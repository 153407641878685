.o-section {
    &.--cms-content {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--small-documents {
        margin-top: var(--y-65);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--documents {
        margin-top: var(--y-65);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--gallery {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--rubrics {
        margin-top: var(--y-65);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--form {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--publications {
        margin-top: max(var(--y-65), 30px);
        margin-bottom: max(var(--y-65), 30px);
        padding-right: var(--x-60);
        padding-left: var(--x-60);
    }

    &.--pagination {
        margin-top: max(var(--y-65), 30px);
        margin-bottom: var(--y-100);
        padding-right: var(--x-60);
        padding-left: var(--x-60);
    }

    &.--events + &.--pagination {
        margin-top: 0;
    }

    &.--paginationV2 {
        margin-top: max(var(--y-65), 30px);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--publications-component {
        margin-top: max(var(--y-100), 55px);
        margin-bottom: max(var(--y-100), 55px);
        padding-right: var(--x-60);
        padding-left: var(--x-60);
    }

    &.--events {
        margin-top: max(var(--y-65), 30px);
        margin-bottom: max(var(--y-65), 30px);
        padding-right: var(--x-60);
        padding-left: var(--x-60);
    }

    &.--event-infos {
        margin-top: max(var(--y-65), 30px);
        margin-bottom: max(var(--y-65), 30px);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--events-component {
        padding-top: max(var(--y-65), 30px);
        padding-bottom: max(var(--y-100), 55px);
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        background-color: rgb(var(--rgb-light-blue));
    }

    &.--showcase + &.--events-component {
        padding-top: max(var(--y-100), 55px);
    }

    &.--search {
        margin-top: max(var(--y-65), 30px);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--results {
        margin-bottom: max(var(--y-65), 30px);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--contact-infos {
        margin-top: max(var(--y-65), 30px);
        margin-bottom: max(var(--y-65), 30px);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--fast-links {
        padding-top: max(var(--y-60), 55px);
        padding-bottom: max(var(--y-100), 55px);
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        background-color: rgb(var(--rgb-light-blue));
    }

    &.--page-title + section:not(.--content-after-image):not(.--filter),
    &.--content-after-image + section {
        margin-top: max(var(--y-65), 30px);
    }
}