.c-button-calendar {
    position: fixed;
    bottom: 30px;
    right: var(--x-60);
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--button-calendar-dimension);
    height: var(--button-calendar-dimension);
    border-radius: 50%;
    background-color: rgb(var(--rgb-cyan));
    transition: background-color 300ms, opacity 300ms;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    &:hover {
        background-color: rgb(var(--rgb-marine));
    }

    svg {
        width: 20px;
        height: 20px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 2px;
    }
}