@use "../abstract/placeholders";

.c-results {
    &__result {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(var(--rgb-marine), 0.25);
    }

    &__result-surtitle {
        display: inline-block;
        margin-bottom: 15px;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-cyan));
    }

    &__result-title {
        margin-bottom: 25px;
        font-family: var(--ff-2);
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));

        mark {
            color: rgb(var(--rgb-cyan));
        }
    }

    &__result-text {
        margin-bottom: 25px;
        font-size: min(var(--fz-17), 1.7rem);
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));

        mark {
            color: rgb(var(--rgb-cyan));
        }
    }

    &__result-button {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.3em;
        text-decoration: none;

        span {
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-cyan));
            transition: color 300ms var(--out-cubic);
        }
    }

    &:hover &__result-button span {
        color: rgb(var(--rgb-cyan));
    }

    &__result-picture-container {
        @extend %border-radius;
        overflow: hidden;
        flex-shrink: 0;
        width: 105px;
        height: 105px;
        margin-left: var(--x-90);
        border-radius: 50%;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__result-picture {
        width: 100%;
        height: 100%;
    }
}