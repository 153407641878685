.c-publication-card {
    display: block;

    &__container {
        padding: max(var(--y-50), 30px) var(--x-40);
        background-color: rgb(var(--rgb-light-blue));
    }

    &__surtitle,
    &__date {
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.4em;
        color: rgba(var(--rgb-marine), 0.7);
    }

    &__surtitle {
        margin-bottom: 20px;
    }

    &__title {
        display: inline-block;
        font-size: max(var(--fz-22), 1.7rem);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-cyan));
    }

    &__date {
        margin-top: 20px;
    }
}