/*
|--------------------------------------------------------------------------
| Les variables de couleur
|--------------------------------------------------------------------------
|
| Déclaration des variables de couleurs du projet
|
*/

:root {
    // #FFFFFF - Blanc
    --rgb-white: 255, 255, 255;

    // #000000 - Noir
    --rgb-black: 0, 0, 0;

    // #E2EDF5 - Bleu pâle
    --rgb-light-blue: 226, 237, 245;

    // #138AEB - Cyan
    --rgb-cyan: 19, 138, 235;

    // #0A2E69 - Marine
    --rgb-marine: 10, 46, 105;

    // #EEA81D - Jaune
    --rgb-yellow: 238, 168, 29;

    // #4A6E92 - Gris pâle (Note)
    --rgb-note: 74, 110, 146;

    // #EF392D - Rouge
    --rgb-red: 239, 57, 45;
}
