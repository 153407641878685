.c-fast-link-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 182px;
    padding: 40px 30px;
    background-color: rgb(var(--rgb-white));

    @media screen and (max-width: 600px) {
        min-height: 100px;
        padding: 10px 20px;
    }

    svg:first-child {
        width: 27px;
        height: 27px;
        margin-bottom: 15px;
        stroke: rgb(var(--rgb-cyan));
        stroke-width: 7px;
        fill: transparent;

        @media screen and (max-width: 600px) {
            width: 23px;
            height: 23px;
            margin-bottom: 8px;
        }
    }

    &__title {
        max-width: 160px;
        font-size: var(--fz-20);
        font-weight: 700;
        line-height: 1.4em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;

        @media screen and (max-width: 400px) { font-size: 1.5rem; }
    }

    &:hover &__title {
        color: rgb(var(--rgb-cyan));
    }

    svg:last-child {
        position: absolute;
        right: 25px;
        bottom: 25px;
        rotate: 90deg;
        width: 18px;
        height: 18px;
        stroke: rgb(var(--rgb-cyan));
        stroke-width: 1.5px;

        @media screen and (max-width: 600px) {
            right: 21px;
            bottom: 16px;
        }
    }
}