.c-textarea-field {
    --textarea-field-color: rgb(var(--rgb-marine));
    --textarea-field-placeholder-color: rgb(var(--rgb-note));
    --textarea-field-background: rgb(var(--rgb-white));
    --textarea-field-border: rgba(var(--rgb-marine), 0.25);
    --textarea-field-border-active: rgb(var(--rgb-marine));
    --textarea-field-border-error: rgb(var(--rgb-red));
}

.c-textarea-field {
    position: relative;

    &__textarea-container {
        position: relative;
    }

    &__textarea {
        width: 100%;
        height: 43px;
        padding: 8px 0;
        font-family: var(--ff-1);
        font-size: var(--fz-16);
        line-height: 1.6em;
        color: var(--textarea-field-color);
        background-color: var(--textarea-field-background);
        border: 0;
        border-bottom: 1px solid var(--textarea-field-border);
        border-radius: 0; // Nécéssaire même si 0 pour éviter les coins ronds en iOS
        box-shadow: 0 0 0 100px var(--textarea-field-background-color) inset; // Choisir la couleur voulu pour le input (Cache le background bleu de l'autofill)
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;
        outline: none;
        resize: none;
        overflow: hidden;

        &[style] { // Éviter le skip quand le textarea a une hauteur donnée par la fonction "textareaHeight"
            margin-bottom: 0;
        }
    }

    &__textarea:focus {
        border-color: var(--textarea-field-border-active);
        border-width: 2px;
        margin-bottom: 0px; // Pour éviter le "skip" de 1px lorsque le border passe de 1px à 2px au focus
    }

    &--error &__textarea {
        border-color: var(--textarea-field-border-error);
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: var(--textarea-field-placeholder-color); }
    ::-moz-placeholder { color: var(--textarea-field-placeholder-color); }
    :-ms-input-placeholder {  color: var(--textarea-field-placeholder-color); }
    :-moz-placeholder { color: var(--textarea-field-placeholder-color); }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        font-size: var(--fz-16) !important;
        border-bottom: 1px solid var(--textarea-field-border-active) !important;
        -webkit-text-fill-color: var(--textarea-field-color);
        caret-color: var(--textarea-field-color);
        margin-bottom: 1px !important;
    }

    :-webkit-autofill::first-line {
        font-family: var(--ff-1);
        font-size: var(--fz-16);
    }
}
