@use "../abstract/placeholders";
@use "../base/typography";

.c-home-banner {
    --swiper-width: 400px;
    @media screen and (max-width: 900px) { --swiper-width: 300px; }
}

.c-home-banner {
    &__container {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: calc((var(--vh, 1vh) * 100) - var(--alert-height) - var(--header-height) - calc(110px - var(--alert-height)));
        min-height: 600px;
        transition: height 500ms var(--in-out-cubic) 1200ms;

        @media screen and (max-height: 880px) { height: calc((var(--vh, 1vh) * 100) - var(--alert-height) - var(--header-height) - calc(60px - var(--alert-height))); }
        @media screen and (max-height: 800px) { height: calc((var(--vh, 1vh) * 100) - var(--alert-height) - var(--header-height)); }
        @media screen and (max-width: 800px) {
            flex-direction: column;
            align-items: initial;
            height: initial;
            min-height: 0;
        }
    }

    &__left {
        position: relative;
        display: flex;
        align-items: stretch;
        width: calc(100% -  var(--swiper-width));
        height: 100%;
        min-height: 425px;

        @media screen and (max-width: 800px) { width: 100%; }
    }

    &__picture-container {
        @extend %border-radius;
        position: relative;
        overflow: hidden;
        width: 100%;
        border-top-right-radius: clamp(75px, 14vw, 100px);

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            background: linear-gradient(26deg, rgba(8, 38, 87, 0.60) 0%, rgba(8, 38, 87, 0.12) 100%);
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__text-block {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        max-width: calc(660px + (var(--x-60) * 2));
        padding-top: var(--y-80);
        padding-right: var(--x-60);
        padding-bottom: var(--y-80);
        padding-left: var(--x-60);
    }

    &__title {
        @extend .c-huge-title;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 600px) { font-size: var(--fz-60); }
        @media screen and (max-width: 500px) { font-size: var(--fz-55); }
        @media screen and (max-width: 400px) { font-size: var(--fz-50); }

        &.u-font-size-60 {
            font-size: min(var(--fz-60), 6rem);

            @media screen and (max-width: 600px) { font-size: var(--fz-55); }
            @media screen and (max-width: 500px) { font-size: var(--fz-50); }
            @media screen and (max-width: 400px) { font-size: var(--fz-45); }
            @media screen and (max-width: 360px) { font-size: 2.6rem; }
        }

        &.u-font-size-55 {
            font-size: min(var(--fz-55), 5.5rem);

            @media screen and (max-width: 600px) { font-size: var(--fz-50); }
            @media screen and (max-width: 500px) { font-size: var(--fz-45); }
            @media screen and (max-width: 400px) { font-size: 2.6rem; }
        }

        &.u-font-size-50 {
            font-size: min(var(--fz-50), 5rem);

            @media screen and (max-width: 600px) { font-size: var(--fz-45); }
            @media screen and (max-width: 500px) { font-size: 2.6rem; }
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--cyan-to-marine;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-stroke;
        width: fit-content;
        margin-top: 30px;

        @media screen and (max-width: 600px) { margin-top: 15px; }
    }

    &__search {
        max-width: 510px;
        margin-top: 60px;

        @media screen and (max-width: 600px) { margin-top: 25px; }
    }

    .c-search-field {
        position: relative;
        width: 100%;

        .c-search-field__input-container {
            width: 100%;
            height: 100%;
        }

        .c-search-field__input {
            width: 100%;
            height: 100%;
            padding: 17px 220px 17px 35px;
            border: none;
            border-radius: 50px;
            box-shadow: 0 0 0 100px rgb(var(--rgb-white)) inset;
            background-color: rgb(var(--rgb-white));

            font-family: var(--ff-1);
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));

            @media screen and (max-width: 600px) {
                padding: 13.5px 90px 13.5px 35px;
                font-size: 1.4rem;
            }

            &::-webkit-input-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &::-moz-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &:-ms-input-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &:-moz-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &::placeholder { color: rgba(var(--rgb-marine), 0.5); }
        }

        .c-search-field__search-button {
            @extend .c-button;
            @extend .c-button--cyan-to-marine;
            @extend .c-button--svg-on-the-right;
            @extend .c-button--svg-fill;
            position: absolute;
            top: 50%;
            right: -1px;
            translate: 0 -50%;

            @media screen and (max-width: 600px) {
                justify-content: center;
                width: 46px;
                height: 46px;
                padding: 0;
                border-radius: 50%;
            }

            span {
                @media screen and (max-width: 600px) { display: none; }
            }

            svg {
                rotate: 0deg;

                @media screen and (max-width: 600px) {
                    translate: 0 0;
                    margin-left: 0;
                }
            }
        }

        .c-search-field__x {
            position: absolute;
            top: 50%;
            right: 190px;
            translate: 0 -50%;
            display: block;
            width: 16px;
            height: 16px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;

            @media screen and (max-width: 800px) { right: 60px; }

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-marine));
                transition: opacity 300ms;
            }
        }

        &[data-inputvalue]:not([data-inputvalue=""]) .c-search-field__x {
            opacity: 1;
            pointer-events: all;
            transition: opacity 300ms;

            &:hover svg {
                opacity: 0.6;
            }
        }
    }

    &__photo-credits {
        position: absolute;
        bottom: 15px;
        left: 15px;
        z-index: 5;

        @media screen and (max-width: 800px) {
            top: 10px;
            bottom: initial;
            left: 10px;
        }
    }

    &__right {
        position: relative;
        isolation: isolate;
        width: var(--swiper-width);
        min-width: 0;
        height: 100%;
        padding-left: 70px;

        @media screen and (max-width: 900px) { padding-left: 30px; }
        @media screen and (max-width: 800px) {
            width: 100%;
            padding-left: var(--x-70);
        }

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            height: 65%;
            background-color: rgb(var(--rgb-light-blue));

            @media screen and (max-width: 800px) { height: 100%; }
        }
    }

    &__swiper {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 90px;

        @media screen and (max-height: 880px) { padding-top: 80px; }
        @media screen and (max-height: 850px) { padding-top: 40px; }
        @media screen and (max-width: 800px) { padding-top: 35px; }
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-pagination {
        --swiper-pagination-top: 60px;
        --swiper-pagination-bottom: auto;
        --swiper-pagination-color: rgb(var(--rgb-yellow));
        --swiper-pagination-bullet-size: 5px;
        --swiper-pagination-bullet-width: 5px;
        --swiper-pagination-bullet-height: 5px;
        --swiper-pagination-bullet-inactive-color: rgb(var(--rgb-yellow));
        --swiper-pagination-bullet-inactive-opacity: 0.5;
        --swiper-pagination-bullet-opacity: 1;
        --swiper-pagination-bullet-horizontal-gap: 12px;
        --swiper-pagination-bullet-border-radius: 50%;

        left: calc(0px - var(--swiper-pagination-bullet-horizontal-gap))!important;
        display: flex;
        align-items: center;
        text-align: initial!important;

        @media screen and (max-height: 880px) { --swiper-pagination-top: 30px; }
        @media screen and (max-height: 850px) { --swiper-pagination-top: 0px; }
        @media screen and (max-width: 800px) {
            --swiper-pagination-top: 20px;
            --swiper-pagination-bullet-size: 4px;
            --swiper-pagination-bullet-width: 4px;
            --swiper-pagination-bullet-height: 4px;
            --swiper-pagination-bullet-horizontal-gap: 11px;
        }
    }

    .swiper-pagination .swiper-pagination-bullet-active {
        width: 7px;
        height: 7px;

        @media screen and (max-width: 800px) {
            width: 6px;
            height: 6px;
        }
    }

    &__showcase {
        display: block;
    }

    &__showcase-picture-container,
    &__showcase-picture {
        width: 100%;
    }

    &__showcase-picture {
        position: relative;
        padding-top: 107%;
    }

    &__showcase-img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__showcase-text-block {
        padding-top: 40px;
        padding-right: 15px;

        @media screen and (max-height: 880px) { padding-top: 30px; }
        @media screen and (max-width: 500px) { padding-right: 0; }
    }

    &__showcase-surtitle {
        display: inline-block;
        margin-bottom: 6px;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.4em;
        color: rgba(var(--rgb-marine), 0.7);
        @media screen and (max-width: 400px) { font-size: 1.3rem; }
    }

    &__showcase-title {
        margin-bottom: 15px;
        font-family: var(--ff-2);
        font-size: min(var(--fz-22), 2.2rem);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-marine));
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media screen and (max-width: 700px) { font-size: var(--fz-20); }
        @media screen and (max-width: 500px) { font-size: 1.7rem; }
        @media screen and (max-width: 400px) { font-size: 1.5rem; }
    }

    &__showcase-button {
        @extend .c-button;
        @extend .c-button--empty-to-marine;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-stroke;
        pointer-events: none;

        span {
            @media screen and (max-width: 400px) { font-size: 1.3rem; }
        }
    }

    &__showcase:hover &__showcase-button {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-marine));
        --button-border-color: rgb(var(--rgb-marine));

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }
    }
}

// Animations
.c-home-banner {
    &__picture-container,
    &__text-block,
    &__swiper {
        translate: 0 50px;
        opacity: 0;
        transition-property: translate, opacity;
        transition-duration: 800ms, 800ms;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1), cubic-bezier(0.33, 1, 0.68, 1);
    }

    &__picture-container {
        transition-delay: 200ms;
    }

    &__text-block {
        transition-delay: 600ms;
    }

    &__swiper{
        transition-delay: 1000ms;
    }

    &[data-fire] &__picture-container,
    &[data-fire] &__text-block,
    &[data-fire] &__swiper {
        translate: 0 0;
        opacity: 1;
    }
}