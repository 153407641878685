@use "../base/typography";

.c-events {
    --events-list-gap-x: 15px;
    --events-list-gap-y: 60px;
    @media screen and (max-width: 1200px) { --events-list-gap-y: 30px; }
    @media screen and (max-width: 700px) { --events-list-gap-y: 15px; }
}

.c-events {
    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--events-list-gap-x) * 2));
        margin-right: calc(0px - var(--events-list-gap-x));
        margin-bottom: calc(0px - var(--events-list-gap-y));
        margin-left: calc(0px - var(--events-list-gap-x));
    }

    &__list-item {
        width: 33.33%;
        padding: 0 var(--events-list-gap-x);
        margin-bottom: var(--events-list-gap-y);

        @media screen and (max-width: 1200px) { width: 50%; }
        @media screen and (max-width: 700px) { width: 100%; }
    }

    &__no-items-title {
        @extend .c-medium-title;
    }
}