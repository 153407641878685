@use "../base/typography";

.c-event-infos {
    --event-info-list-gap-x: 34px;
}

.c-event-infos {
    &__title {
        @extend .c-medium-title;
        margin-bottom: 20px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--event-info-list-gap-x) * 2));
        margin-right: calc(0px - var(--event-info-list-gap-x));
        margin-left: calc(0px - var(--event-info-list-gap-x));
    }

    &__list-item {
        width: 50%;
        padding: 0 var(--event-info-list-gap-x);

        @media screen and (max-width: 800px) { width: 100%; };
    }

    &__info {
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(var(--rgb-marine), 0.25);

        span:last-child {
            font-size: 1.5rem;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));
        }
    }

    a.c-event-infos__info {
        span:last-child span {
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-cyan));
            transition: color 300ms var(--out-cubic);
        }

        &:hover span:last-child span {
            color: rgb(var(--rgb-cyan));
        }

        span:last-child svg {
            width: 13px;
            height: 13px;
            margin-left: 5px;
            fill: rgb(var(--rgb-cyan));
        }
    }

    &__svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        margin-right: 15px;
        background-color: rgb(var(--rgb-light-blue));
        border-radius: 10px;

        svg {
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-cyan));
            stroke-width: 2px;
        }
    }
}