.c-pagination {
    &__container,
    &__list {
        display: flex;
        align-items: center;
    }

    &__container {
        justify-content: center;
        width: 100%;
    }

    &__content {
        display: flex;
        align-items: center;
        padding: 12px;
        border: 1px solid rgba(var(--rgb-marine), 0.25);
        border-radius: 100px;
    }

    &__list {
        margin: 0 10px;
    }

    &__list-item:not(:last-child) {
        margin-right: 16px;
    }

    &__page {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        border-radius: 50%;

        &.--active {
            background-color: rgb(var(--rgb-yellow));
            pointer-events: none;
        }

        span {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.2em;
            color: rgb(var(--rgb-marine));
            transition: opacity 300ms;
        }

        &:hover span {
            opacity: 0.6;
        }

        &.--active span {
            color: rgb(var(--rgb-white));
            pointer-events: none;
        }
    }

    &__arrow {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        &.--inactive {
            opacity: 0.4;
            pointer-events: none;
        }

        &.--prev {
            rotate: 90deg;
        }

        &.--next {
            rotate: -90deg;
        }

        svg {
            width: 14px;
            height: 14px;
        }
    }
}

.c-paginationV2 {
    &__container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 35px;
        border-top: 1px solid rgba(var(--rgb-marine), 0.25);
    }

    &__button {
        display: flex;
        align-items: center;

        &.--prev {
            margin-right: 20px;
        }

        &.--inactive {
            opacity: 0.6;
            pointer-events: none;

            svg {
                flex-shrink: 0;
                width: 14px;
                height: 13px;
                stroke: rgb(118, 121, 128);
                stroke-width: 2px;
            }
        }

        svg {
            flex-shrink: 0;
            width: 14px;
            height: 13px;
            stroke: rgb(var(--rgb-cyan));
            stroke-width: 2px;
        }

        &.--prev svg {
            rotate: -90deg;
            margin-right: 12px;
        }

        &.--next svg {
            rotate: 90deg;
            margin-left: 12px;
        }
    }

    &__button-text {
        span {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));
            transition: color 300ms;
        }
    }

    &__button:hover &__button-text span {
        color: rgb(var(--rgb-cyan));
    }
}

.o-layout.--page-publication {
    .c-paginationV2 {
        &__button-text > span:first-child {
            span:first-child {
                @media screen and (max-width: 500px) { display: none; }
            }

            > span:last-child {
                display: block;

                @media screen and (min-width: 501px) { display: none; }
            }
        }
        &__button-text > span:last-child {
            display: none;
        }
    }
}

.o-layout.--page-event {
    .c-paginationV2 {
        &__button-text > span:first-child {
            display: none;
        }

        &__button-text > span:last-child {
            > span:first-child {
                @media screen and (max-width: 500px) { display: none; }
            }

            > span:last-child {
                display: block;

                @media screen and (min-width: 501px) { display: none; }
            }
        }
    }
}