.c-fast-links {
    --fast-links-list-gap-x: 10px;
    --fast-links-list-gap-y: 20px;
    @media screen and (max-width: 600px) { --fast-links-list-gap-y: 15px; }
}

.c-fast-links {
    &__container {
        display: flex;
        align-items: stretch;

        @media screen and (max-width: 900px) {
            flex-direction: column;
            align-items: initial;
        }
    }

    &__left {
        position: relative;
        overflow: hidden;
        isolation: isolate;
        display: flex;
        align-items: flex-end;
        width: 380px;
        min-height: 384px;
        padding: 30px;
        background-color: rgb(var(--rgb-marine));

        @media screen and (max-width: 1000px) { width: 280px; }
        @media screen and (max-width: 900px) {
            width: 100%;
            min-height: 282px;
            margin-bottom: 22px;
        }
    }

    &__shape {
        position: absolute;
        z-index: -1;

        &:first-child {
            top: 0;
            left: max(var(--x-100), 75px);
            width: clamp(100px, 9.65vw, 140px);
        }
        &:nth-child(2) {
            bottom: 0;
            left: 0;
            width: clamp(145px, 13.8vw, 200px);
        }
        &:nth-child(3) {
            right: 0;
            bottom: 0;
            width: clamp(100px, 9.65vw, 140px);
        }
    }

    &__title {
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 900px) { font-size: max(var(--fz-25), 2.2rem); }
    }

    &__right {
        flex: 1;
        padding-left: 20px;

        @media screen and (max-width: 900px) {
            flex: initial;
            width: 100%;
            padding-left: 0;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--fast-links-list-gap-x) * 2));
        margin-right: calc(0px - var(--fast-links-list-gap-x));
        margin-bottom: calc(0px - var(--fast-links-list-gap-y));
        margin-left: calc(0px - var(--fast-links-list-gap-x));
    }

    &__list-item {
        width: 33.33%;
        padding: 0 var(--fast-links-list-gap-x);
        margin-bottom: var(--fast-links-list-gap-y);

        @media screen and (max-width: 1200px) { width: 50%; }
        @media screen and (max-width: 600px) { width: 100%; }
    }
}