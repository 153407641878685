/*
|--------------------------------------------------------------------------
| Root
|--------------------------------------------------------------------------
|
| Déclaration de tout ce qui doit se retrouver dans le sélecteur :root.
|
*/

@use "../abstract/mixins" as *;

:root {
    /*
    |--------------------------------------------------------------------------
    | GÉNÈRE LES VARIABLES CSS DES FONT-SIZES
    |--------------------------------------------------------------------------
    */
    @include fontSizes(
        "14", "16", "17", "18", "20", "21", "22", "25", "30", "35", "40", "45", "50", "55", "60", "65",
    );

    /*
    |--------------------------------------------------------------------------
    | GÉNÈRE LES VARIABLES CSS DES ESPACEMENTS
    |--------------------------------------------------------------------------
    */
    @include spacings(
        "x-25", "x-30", "x-40", "x-45", "x-50", "x-60", "x-70", "x-75", "x-80", "x-90", "x-100", "x-180", "x-240",
        "y-20", "y-25", "y-30", "y-35", "y-40", "y-45", "y-50", "y-60", "y-65", "y-70", "y-80",  "y-90", "y-100",
    );

    /*
    |--------------------------------------------------------------------------
    | VARIABLES CSS
    |--------------------------------------------------------------------------
    */

    // Header
    --header-height: 110px;
    @media screen and (max-width: 500px) { --header-height: 95px; }

    // Crédit photo
    --tooltip-dimensions: 15px;

    // Bouton de calendrier
    --button-calendar-dimension: 52px;

    // Variables de l'alerte
    --alert-height: 0px;
    --alert-transition-duration: 500ms;
    --alert-transition-timing-function: var(--in-out-cubic);
    --alert-transition-delay: 0ms;

    // Variables du footer
    --footer-copyright-height: 83px;
    @media screen and (max-width: 600px) { --footer-copyright-height: 162px; }
}
