@use "../../abstract/placeholders";

.control-multi-file-uploader,
.c-multiple-files-field {
    --multiple-files-field-container-background-color: #f6f6f6;
    --multiple-files-field-container-border-color: #b2b2b2;
    --multiple-files-field-container-border-radius: 5px;

    --multiple-files-field-button-height: 100px;
    --multiple-files-field-button-font-size: var(--fz-15);
    --multiple-files-field-button-font-weight: 500;
    --multiple-files-field-button-line-height: 1.3em;
    --multiple-files-field-button-color: rgb(var(--rgb-black));
    --multiple-files-field-button-svg-size: 10px;
    --multiple-files-field-button-svg-color: rgb(var(--rgb-black));
    --multiple-files-field-button-background-color: #f6f6f6;
    --multiple-files-field-button-background-color-hover: #d4d4d4;

    --multiple-files-field-file-background-color: #f6f6f6;
    --multiple-files-field-file-preview-size: 100px;
    --multiple-files-field-file-preview-border-radius: 5px;
    --multiple-files-field-file-detail-font-size: var(--fz-14);
    --multiple-files-field-file-detail-font-weight: 400;
    --multiple-files-field-file-detail-line-height: 1.5em;
    --multiple-files-field-file-detail-color: rgb(var(--rgb-black));
    --multiple-files-field-file-x-color: rgb(var(--rgb-white));
    --multiple-files-field-file-x-background-color: rgb(var(--rgb-3));
    --multiple-files-field-file-x-background-color-hover: rgb(var(--rgb-black));
    --multiple-files-field-file-x-background-size: 20px;

    // Attention: Si vous modifiez autres choses que les variables ci-haut, assurez-vous que les modifications s'exécutent
    // sur les deux champs multiple-files-field avec Renatio ou sans Renatio. (ex: taille, espace, positionnement, etc.)
}

// Avec Renatio
.control-multi-file-uploader {
    background-color: var(--multiple-files-field-container-background-color);
    border: 1px dashed var(--multiple-files-field-container-border-color);
    border-radius: var(--multiple-files-field-container-border-radius);
    overflow: hidden;

    .container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .dz-clickable {
        height: var(--multiple-files-field-button-height);
        background-color: var(--multiple-files-field-button-background-color);
        cursor: pointer;
        transition-property: color, background-color;
        transition-duration: 300ms;

        &:hover {
            background-color: var(--multiple-files-field-button-background-color-hover);
        }
    }

    .content {
        font-size: var(--multiple-files-field-button-font-size);

        .placeholder {
            position: absolute;
            top: 50px;
            right: 20px;
            left: 20px;
            line-height: var(--multiple-files-field-button-line-height);
            font-weight: var(--multiple-files-field-button-font-weight);
            color: var(--multiple-files-field-button-color);
            text-align: center;
            pointer-events: none;
            transform: translate(0, -50%);
            transition-property: color, background-color;
            transition-duration: 300ms;

            &__svg {
                width: var(--multiple-files-field-button-svg-size);
                height: var(--multiple-files-field-button-svg-size);
                fill: var(--multiple-files-field-button-svg-color);
                margin-left: 10px;
                margin-bottom: -1px;
            }

            .upload-full-text {
                display: block;

                @media screen and (max-width: 560px) {
                    display: none
                }
            }

            .upload-truncated-text {
                display: none;

                @media screen and (max-width: 560px) {
                    display: block;
                }
            }
        }

        .dz-preview {
            display: flex;
            align-items: center;
            position: relative;
            min-height: 100px;
            padding-left: 120px;
            padding-right: 35px;
            margin: 20px;

            .thumbnail {
                position: absolute;
                width: var(--multiple-files-field-file-preview-size);
                height: var(--multiple-files-field-file-preview-size);
                top: 0;
                left: 0;
            }

            .thumbnail {
                border-radius: var(--multiple-files-field-file-preview-border-radius);
                @extend %border-radius;
                overflow: hidden;
            }

            .dz-details {
                flex-wrap: wrap;
                font-size: var(--multiple-files-field-file-detail-font-size);
                line-height: var(--multiple-files-field-file-detail-line-height);
                color: var(--multiple-files-field-file-detail-color);

                .dz-filename,
                .dz-size {
                    width: 100%;
                    word-break: break-all;
                    margin: 6px 0;
                }
            }

            .action-panel {
                position: absolute;
                width: var(--multiple-files-field-file-x-background-size);
                height: var(--multiple-files-field-file-x-background-size);
                top: 10px;
                right: 10px;
                color: var(--multiple-files-field-file-x-color);
                background: var(--multiple-files-field-file-x-background-color);
                border-radius: 50%;
                cursor: pointer;
                z-index: 4;
                transition-property: color, background-color;
                transition-duration: 300ms;

                &:hover {
                    background-color: var(--multiple-files-field-file-x-background-color-hover);
                }

                a {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: var(--multiple-files-field-file-x-color);
                    transform: translate(-50%, -50%);
                }
            }

            .dz-error-mark {
                display: none;
            }

            .dz-error-message {
                position: absolute;
                bottom: 5px;
                color: #458a91;
            }
        }
    }
}

// Sans Renatio
.c-multiple-files-field {
    position: relative;

    &__container {
        border: 1px dashed var(--multiple-files-field-container-border-color);
        border-radius: var(--multiple-files-field-container-border-radius);
        overflow: hidden;
    }

    &__button-input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__button-label {
        display: flex;
        width: 100%;
        min-height: var(--multiple-files-field-button-height);
        padding: 20px 20px;
        align-items: center;
        justify-content: center;
        background-color: var(--multiple-files-field-button-background-color);
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    &__button-input[disabled] + &__button-label {
        pointer-events: none;
    }

    &__button-label:hover {
        background-color: var(--multiple-files-field-button-background-color-hover);
    }

    &__button-label-text {
        font-size: var(--multiple-files-field-button-font-size);
        font-weight: var(--multiple-files-field-button-font-weight);
        line-height: var(--multiple-files-field-button-line-height);
        text-align: center;
        color: var(--multiple-files-field-button-color);
    }

    &__button-input[disabled] + &__button-label .c-multiple-files-field__button-label-text {
        opacity: 0.6;
    }

    &__button-label-svg {
        width: var(--multiple-files-field-button-svg-size);
        height: var(--multiple-files-field-button-svg-size);
        fill: var(--multiple-files-field-button-svg-color);
        margin-left: 10px;
        margin-bottom: -1px;
    }

    &__file {
        height: 0px; // Changer en js
        background-color: var(--multiple-files-field-file-background-color);
        opacity: 0; // Changer en js
        overflow: hidden;
    }

    &__file-container {
        display: flex;
        align-items: center;
        padding: 20px;
    }

    &__file-preview-item {
        padding-right: 20px;
    }

    &__file-preview-picture {
        width: var(--multiple-files-field-file-preview-size);
        height: var(--multiple-files-field-file-preview-size);
        border-radius: var(--multiple-files-field-file-preview-border-radius);
        @extend %border-radius;
        overflow: hidden;
    }

    &__file-text-item {
        flex: 1;
        padding-right: 20px;
    }

    &__file-title,
    &__file-size {
        display: block;
        font-size: var(--multiple-files-field-file-detail-font-size);
        font-weight: var(--multiple-files-field-file-detail-font-weight);
        line-height: var(--multiple-files-field-file-detail-line-height);
        color: var(--multiple-files-field-file-detail-color);
        word-break: break-all;
    }

    &__file-title {
        margin-bottom: 6px;
    }

    &__file-delete-item {
        align-self: flex-start;
        padding-top: 10px;
        padding-right: 10px;
    }

    &__file-delete-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--multiple-files-field-file-x-background-size);
        height: var(--multiple-files-field-file-x-background-size);
        background-color: var(--multiple-files-field-file-x-background-color);
        border-radius: 50%;
        transition: background-color 0.3s ease;
    }

    &__file-delete-link:hover {
        background-color: var(--multiple-files-field-file-x-background-color-hover);
    }

    &__file-delete-x {
        position: relative;
        width: 8px;
        height: 8px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            top: 50%;
            left: 50%;
            background-color: var(--multiple-files-field-file-x-color);
            transition: background-color 300ms;
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}
