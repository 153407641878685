.c-overlay-dropdown {
    --image-width: 350px;
}

.c-overlay-dropdown {
    position: fixed;
    top: calc(var(--header-height) + var(--alert-height));
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    visibility: hidden;
    pointer-events: none;
    transition-property: top;
    transition-duration: var(--alert-transition-duration);
    transition-timing-function: var(--alert-transition-timing-function);
    transition-delay: var(--alert-transition-delay);

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 700ms var(--in-out-quart);
        z-index: -1;
        cursor: default;
    }

    &__interfaces {
        max-height: calc((var(--vh, 1vh) * 100) - var(--alert-height) - var(--header-height));
        transition: height 900ms var(--in-out-quart);
    }

    &__interfaces > &__interface-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 0px;
        background-color: rgb(var(--rgb-white));
        transition: height 900ms var(--in-out-quart);
    }

    &__interfaces > &__interface-container:has(.is-active) {
        height: 100%;
    }

    &__interface[data-dropdown-interface-level="1"] {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        min-height: 560px;
        pointer-events: none;
        transition: opacity 300ms;

        @media screen and (max-height: 768px) { max-height: 100%; }

        &.is-active {
            pointer-events: all;
        }
    }

    &__primary-buttons {
        width: 350px;
        height: 100%;
        border-right: 1px solid rgba(var(--rgb-marine), 0.25);
    }

    &__primary-buttons &__content {
        padding: 40px;
    }

    &__primary-button {
        translate: 0 -25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(var(--rgb-marine), 0.25);
        opacity: 0;
        text-align: left;
        transition: opacity 900ms 0ms, translate 800ms 0ms var(--out-cubic), border-bottom 300ms 0ms;

        &.is-active {
            border-bottom: 2px solid rgb(var(--rgb-cyan));
            padding-bottom: 19px;
        }

        span {
            font-family: var(--ff-2);
            font-size: var(--fz-20);
            font-weight: 600;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-cyan));
        }

        svg {
            rotate: 90deg;
            flex-shrink: 0;
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-cyan));
            stroke-width: 1.5px;
        }
    }

    &__interface[data-dropdown-interface-level="1"].is-active &__primary-button {
        translate: 0;
        opacity: 1;
        transition: opacity 900ms 300ms, translate 800ms 300ms var(--out-cubic);
    }

    &__interface[data-dropdown-interface-level="1"] > &__interface-container {
        position: relative;
        flex: 1;
    }

    &__interface[data-dropdown-interface-level="2"] {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        translate: -50px 0;
        height: 100%;
        padding-right: 60px;
        padding-left: 60px;
        opacity: 0;
        pointer-events: none;
        transition-property: opacity, translate;
        transition-duration: 700ms;
        transition-timing-function: var(--in-out-quart);

        &.is-active {
            translate: 0;
            opacity: 1;
            pointer-events: all;
        }
    }

    &__interface[data-dropdown-interface-level="2"] &__content {
        min-height: 560px;
        padding-top: 60px;

        @media screen and (max-height: 768px) { max-height: 100%; }
    }

    &__list {
        padding-bottom: 60px;
    }

    &__link {
        display: block;
        padding-top: 6px;
        padding-bottom: 6px;

        span {
            font-size: var(--fz-16);
            font-weight: 600;
            color: rgb(var(--rgb-marine));
            line-height: 1.6em;
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-note));
        }
    }

    &__picture-container {
        width: var(--image-width);
        opacity: 0;
        transition: opacity 400ms;
    }

    &__interface[data-dropdown-interface-level="1"].is-active &__picture-container {
        opacity: 1;
        transition: opacity 900ms 0ms;
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__close-button {
        position: absolute;
        top: 40px;
        right: calc(40px + var(--image-width));
        width: 14px;
        height: 14px;
        opacity: 0;
        transition: opacity 300ms 0ms;

        svg {
            width: 100%;
            height: 100%;
            stroke: rgb(var(--rgb-marine));
            transition: stroke 300ms;
        }

        &:hover svg {
            stroke: rgb(var(--rgb-cyan));
        }
    }

    &__scrollbar-container {
        height: 100%;
    }

    &__scrollbar {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--rgb-marine), 0.25);
            border-radius: 10px;
        }
    }
}

.show-overlay-dropdown {
    .c-overlay-dropdown {
        pointer-events: all;
        visibility: visible;

        &__close-button {
            opacity: 1;
            transition: opacity 300ms 600ms;
        }
    }

    body {
        --alert-height: 0px;
    }

    .c-header.--home .c-header__container {
        padding-bottom: 21px;
        border-bottom: 1px solid rgba(var(--rgb-marine), 0.25);
    }
}

.show-overlay-dropdown:not(.closing-overlay-dropdown) {
    .c-overlay-dropdown {
        z-index: 25;

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }
}

.show-overlay-dropdown.closing-overlay-dropdown {
    .c-overlay-dropdown {
        &__close-button {
            opacity: 0;
            transition: opacity 300ms 0ms;
        }
    }

    .c-header.--home .c-header__container {
        padding-bottom: 21px;
        border-bottom: 1px solid rgba(var(--rgb-marine), 0.25);
    }
}