@use "../../base/typography";

.c-button-fastlinks {
    @extend .c-button;
    @extend .c-button--cyan-to-marine;
    @extend .c-button--svg-on-the-right;
    @extend .c-button--svg-stroke;
    position: fixed;
    bottom: 20px;
    left: 50%;
    translate: -50% 0;
    white-space: nowrap;
    transition: opacity 300ms;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    @media screen and (min-width: 1201px) { display: none; }

    svg {
        rotate: 0deg;
        stroke-width: 3px;
        transition: opacity 300ms;

        &:last-child {
            position: absolute;
            top: 50%;
            right: 30px;
            translate: 0 -50%;
            opacity: 0;
        }
    }
}