.c-breadcrumbs {
    &__container {
        padding: 20px var(--x-40);
        background-color: rgb(var(--rgb-white));
    }

    &__list {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));
        text-align: center;
    }

    &__home-link {
        translate: 0 1px;
        display: inline-block;
        margin-right: 15px;

        svg {
            width: 12px;
            height: 12px;
            fill: rgb(var(--rgb-cyan));
            transition: fill 300ms;
        }

        &:hover svg {
            fill: rgb(var(--rgb-marine));
        }
    }

    &__link-item {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }
    }

    &__space {
        translate: 0 1px;
        display: inline-block;
        width: 2px;
        height: 11px;
        margin-right: 15px;
        margin-left: 15px;
        background-color: rgb(var(--rgb-cyan));
    }

    &__home-link + &__link-item &__space,
    &__home-link + &__item &__space,
    &__link-item:first-child &__space,
    &__item:first-child &__space {
        display: none;
    }
}