@use "../base/typography";

.s-cms-content {
    > :first-child {
        margin-top: 0 !important;
    }

    > :last-child {
        margin-bottom: 0 !important;
    }

    h2 + h3,
    h3 + h4 {
        margin-top: 0;
    }

    h2 {
        @extend .c-large-title;
        margin-top: var(--y-65);
        margin-bottom: var(--y-30);
    }

    h3 {
        @extend .c-medium-title;
        margin-top: var(--y-65);
        margin-bottom: var(--y-30);
    }

    h4 {
        @extend .c-small-title;
        margin-top: var(--y-65);
        margin-bottom: var(--y-30);
    }

    p {
        @extend .c-paragraph;
        margin-top: var(--y-25);
        margin-bottom: var(--y-25);
        --buttons-gap: 15px;

        &.buttons {
            display: flex;
            flex-wrap: wrap;
            align-items: start;
            width: calc(100% + var(--buttons-gap));
            margin-left: calc(var(--buttons-gap) / -2);
            margin-right: calc(var(--buttons-gap) / -2);

            > * {
                margin-right: calc(var(--buttons-gap) / 2);
                margin-left: calc(var(--buttons-gap) / 2);
                margin-top: 7px;
                margin-bottom: 7px;
            }

            &--first {
                margin-top: calc(var(--y-40) - 7px);
            }

            &--last {
                margin-bottom: calc(var(--y-40) - 7px);
            }
        }
    }

    pre {
        @extend .c-note;
        margin: var(--y-25) 0;
    }

    a:not(.primary-button):not(.secondary-button):not(.docs) {
        @extend .c-underlined-link;

        svg {
            margin-left: 0.25em;
            margin-right: 0.35em;
        }

        &.link-image,
        &.link-image:hover {
            box-shadow: none;

            svg {
                display: none;
            }
        }
    }

    // Link in others elements
    h2,
    h3,
    h4,
    p,
    pre,
    blockquote {
        a:not(.primary-button):not(.secondary-button):not(.docs) {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            color: inherit;
        }
    }

    li,p {
        a:not(.primary-button):not(.secondary-button):not(.docs) {
            font-weight: 500;
        }
        b,strong {
            a:not(.primary-button):not(.secondary-button):not(.docs) {
                font-weight: inherit;
            }
        }
    }

    a.primary-button {
        @extend .c-button;
        @extend .c-button--cyan-to-marine;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;
    }

    a.secondary-button {
        @extend .c-button;
        @extend .c-button--empty-to-marine;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;
    }

    // Même style que document sous contenu
    a.docs {
        display: flex;
        align-items: center;
        min-height: 77px;
        padding: 25px 40px 25px 0;
        text-decoration: none;
        border-bottom: 2px solid rgb(var(--rgb-cyan));

        font-family: var(--ff-1);
        font-size: var(--fz-17);
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-cyan));
        }

        @media screen and (max-width: 1024px) { padding-right: 30px; }
        @media screen and (max-width: 768px)  { padding-right: 20px; }
        @media screen and (max-width: 600px)  { padding-right: 10px; }

        span {
            flex: 1;
            padding-right: 50px;

            @media screen and (max-width: 1024px) { padding-right: 40px; }
            @media screen and (max-width: 768px)  { padding-right: 30px; }
        }

        svg {
            width: 15px;
            height: 15px;
            fill: rgb(var(--rgb-marine));
            transition-property: stroke, fill;
            transition-duration: 300ms;
            transition-timing-function: linear;
        }

        &:hover svg {
            fill: rgb(var(--rgb-cyan));
        }
    }

    ol,
    ul {
        margin: var(--y-30) 0;
    }

    ol {
        @extend .c-list;
        @extend .c-list--ordered;
    }

    ul {
        @extend .c-list;
        @extend .c-list--unordered;
    }

    blockquote {
        @extend .c-quote;
        margin: var(--y-35) 0;
    }

    .table-wrapper {
        @extend .c-table;
        margin-top: var(--y-30);
        margin-bottom: var(--y-65);

        // Ajouter cette ligne si les bordures du tableau sont arrondies
        // @extend .c-table--rounded-borders;
    }

    .emphasis {
        @extend .c-emphasis;
        margin-top: var(--y-30);
        margin-bottom: var(--y-65);
    }

    .table-wrapper,
    .emphasis {
        & + pre { // Modifier la marge pour les notes sous les tableaux et bloc d'emphases
            margin-top: calc(0px - var(--y-45));
        }

        & + .table-wrapper,
        & + .emphasis { // Modifier la marge pour les tableaux sous les tableaux
            margin-top: calc(0px - var(--y-35));
        }
    }

    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    sup {
        position: relative;
        top: -0.3em;
        font-size: max(0.7em, 1.2rem);
        vertical-align: baseline;
    }

    sub {
        position: relative;
        top: 0.3em;
        font-size: max(0.7em, 1.2rem);
        vertical-align: baseline;
    }

    .fr-video {

        //Rompre le texte
        &.fr-dvb {
            .videoWrapper {
                display: block;
                float: none;
                margin: 30px auto;
            }

            //Aligner à gauche
            &.fr-fvl {
                .videoWrapper { margin-left: 0; }
            }

            //Aligner à droite
            &.fr-fvr {
                .videoWrapper { margin-right: 0; }
            }
        }

        //En ligne
        &.fr-dvi {
            .videoWrapper {
                display: inline-block;
                float: none;
                vertical-align: bottom;
                width: 100%;
            }

            //Aligner à gauche
            &.fr-fvl {
                .videoWrapper {
                    float: left;
                    margin: 10px 20px 10px 0;
                }
            }

            //Aligner à droite
            &.fr-fvr {
                .videoWrapper {
                    float: right;
                    margin: 10px 0 10px 20px;
                }
            }
        }

        .videoWrapper {
            max-width: 560px;

            .ytVideo {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
    }

    video {
        max-width: 560px;
        width: 100%;
    }

    img { // Image no caption
        position: relative;
        max-width: 100%;

        &.fr-dib {
            display: block;
            float: none;
            margin: 30px auto;

            &.fr-fil {
                margin-left: 0;
            }

            &.fr-fir {
                margin-right: 0;
            }
        }

        &.fr-dii {
            display: inline-block;
            float: none;
            vertical-align: bottom;

            &.fr-fil {
                float: left;
                margin: 10px 20px 10px 0;
            }

            &.fr-fir {
                float: right;
                margin: 10px 0 10px 20px;
            }
        }
    }

    .fr-img-caption { // Image with caption
        img {
            width: 100%;
        }

        //Rompre le texte
        &.fr-dib {
            display: block;
            float: none;
            margin: 30px auto;
            text-align: center;

            //Aligner à gauche
            &.fr-fil {
                margin-left: 0;
                text-align: left;
            }

            //Aligner à droite
            &.fr-fir {
                margin-right: 0;
                text-align: right;
            }

            .fr-img-wrap .fr-inner {
                display: block;
                @extend .c-note;
            }
        }

        //En ligne
        &.fr-dii {
            display: inline-block;
            float: none;
            text-align: center;
            vertical-align: bottom;

            //Aligner à gauche
            &.fr-fil {
                float: left;
                margin-left: 0;
                text-align: left;
            }

            //Aligner à droite
            &.fr-fir {
                float: right;
                margin-right: 0;
                text-align: right;
            }
        }
    }

    span.fr-fic.oc-img-rounded img,
    img.fr-fic.oc-img-rounded {
        border-radius: 100%;
        background-clip: padding-box;
    }

    span.fr-fic.oc-img-bordered img,
    img.fr-fic.oc-img-bordered {
        border: solid 10px #ccc;
    }

    // Rubrique
    &--rubric {
        h3 {
            margin-top: 50px;
            margin-bottom: 25px;
            font-size: 2.2rem;
            line-height: 1.45em;
            font-weight: 500;

            @media screen and (max-width: 375px) { font-size: 2.1rem; }
            @media screen and (max-width: 320px) { font-size: 2rem; }
        }

        h4 {
            margin-top: 50px;
            margin-bottom: 25px;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 1.5em;
        }
    }
}
