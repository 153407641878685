@use "../../base/typography";

.c-overlay-popup {
    --overlay-popup-width: 520px;
    --overlay-popup-padding: 80px 40px;
    @media screen and (max-width: 1200px) { --overlay-popup-padding: 80px 30px; }
    @media screen and (max-width: 1024px) { --overlay-popup-padding: 70px 22px 50px;}
}

.c-overlay-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 130;

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: var(--overlay-popup-width);
        max-width: calc(100% - var(--x-40) - var(--x-40));
        max-height: calc(var(--vh, 1vh) * 90);
        padding: var(--overlay-popup-padding);
        background-color: rgb(var(--rgb-white));
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        transition: transform 0.8s var(--in-out-quart), opacity 0.8s var(--in-out-quart);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));
        text-align: center;
        margin-bottom: 20px;
    }

    &__close-button {
        display: flex;
        align-items: center;
        position: absolute;
        top: 20px;
        right: 40px;
        transform: translate3d(0, 0, 0);
        z-index: 3;

        @media screen and (max-width: 1200px) { right: 30px; }
        @media screen and (max-width: 1024px) { right: 22px; }
    }

    &__close-button-circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-light-blue));
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: background-color 300ms;

        &:hover {
            background-color: rgb(var(--rgb-marine));
        }

        @media screen and (max-width: 500px) {
            width: 40px;
            height: 40px;
        }
    }

    &__close-button-x {
        position: relative;
        z-index: 1;
        width: 16px;
        height: 16px;
    }

    &__close-button-line {
        width: 100%;
        height: 2px;
        background-color: rgb(var(--rgb-marine));
        position: absolute;
        top: 50%;
        left: 50%;
        transition: background-color 300ms;

        &:first-child {
            transform: translate3d(-50%, -50%, 0) rotate(45deg);
        }

        &:last-child {
            transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        }
    }

    &__close-button-circle:hover &__close-button-line {
        background-color: rgb(var(--rgb-white));
    }

    &__description {
        @extend .c-paragraph;

        text-align: center;
    }

    &__button {
        @extend .c-button;
        @extend .c-button--cyan-to-marine;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;

        margin-top: var(--y-40);
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 0.5s;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-popup {
    .c-alert {
        position: fixed;
    }

    .c-overlay-popup {
        visibility: visible;
        pointer-events: all;

        &__container {
            opacity: 1;
            transform: translate(-50%, -50%);
            pointer-events: all;
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0.7);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-popup.closing-overlay-popup {
    .c-overlay-popup {
        &__container {
            opacity: 0;
            transform: translate(-50%, -40%);
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0);
        }
    }
}
