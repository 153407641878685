@use "../abstract/placeholders";

.c-contact-infos {
    --blocks-list-gap-x: 23px;
    --blocks-list-gap-y: 30px;
}

.c-contact-infos {
    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--blocks-list-gap-x) * 2));
        margin-right: calc(0px - var(--blocks-list-gap-x));
        margin-bottom: calc(0px - var(--blocks-list-gap-y));
        margin-left: calc(0px - var(--blocks-list-gap-x));
    }

    &__block {
        width: 50%;
        padding: 0 var(--blocks-list-gap-x);
        margin-bottom: var(--blocks-list-gap-y);

        @media screen and (max-width: 1200px) { width: 100%; }
    }

    &__block-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        min-height: 100%;
        padding-top: 30px;
        padding-right: 50px;
        padding-bottom: 25px;
        padding-left: 30px;
        border-top-right-radius: clamp(65px, 7vw, 100px);
        background-color: rgb(var(--rgb-light-blue));
    }

    &__title {
        margin-bottom: 20px;
        font-size: var(--fz-22);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-marine));
    }

    &__address {
        display: inline-block;
        margin-bottom: 20px;

        span {
            margin-bottom: 20px;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1.4em;
            color: rgb(var(--rgb-marine));
        }
    }

    &__list-item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        svg {
            translate: 0 1px;
            flex-shrink: 0;
            width: 18px;
            height: 18px;
            margin-right: 12px;
            stroke: rgb(var(--rgb-cyan));
            stroke-width: 2px;
        }

        &.--schedule svg {
            stroke-width: 3px;
        }

        a, button {
            span {
                box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-cyan));
            }
        }

        span,
        a span,
        button span {
            font-family: var(--ff-1);
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1.4em;
            color: rgb(var(--rgb-marine));
            transition: color 300ms;
        }

        a:hover span,
        button:hover span {
            color: rgb(var(--rgb-cyan));
        }
    }

    &__picture-container {
        @extend %border-radius;
        overflow: hidden;
        flex-shrink: 0;
        width: 98px;
        height: 98px;
        margin-left: 30px;
        border-radius: 50%;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }
}