@use "../../base/typography";

.c-overlay-fast-links {
    --fast-links-list-gap-x: 10px;
    --fast-links-list-gap-y: 20px;
    @media screen and (max-width: 600px) { --fast-links-list-gap-y: 15px; }
}

.c-overlay-fast-links {
    position: fixed;
    top: calc(var(--header-height) + var(--alert-height));
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
    transition-property: top;
    transition-duration: var(--alert-transition-duration);
    transition-timing-function: var(--alert-transition-timing-function);
    transition-delay: var(--alert-transition-delay);

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 700ms var(--in-out-quart);
        z-index: -1;
        cursor: default;
    }

    &__container {
        overflow-y: auto;
        overflow-x: hidden;
        translate: 0 -100%;
        max-height: calc((var(--vh, 1vh) * 100) - var(--alert-height) - var(--header-height));
        padding-top: var(--y-60);
        padding-right: var(--x-100);
        padding-bottom: calc(var(--y-70) + 55px);
        padding-left: var(--x-100);
        background-color: rgb(var(--rgb-white));
        transition: translate 900ms var(--in-out-quart);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--fast-links-list-gap-x) * 2));
        margin-right: calc(0px - var(--fast-links-list-gap-x));
        margin-bottom: calc(0px - var(--fast-links-list-gap-y));
        margin-left: calc(0px - var(--fast-links-list-gap-x));

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--rgb-marine), 0.25);
            border-radius: 10px;
        }
    }

    &__list-item {
        width: 25%;
        padding: 0 var(--fast-links-list-gap-x);
        margin-bottom: var(--fast-links-list-gap-y);

        @media screen and (max-width: 1100px) { width: 33.33%; }
        @media screen and (max-width: 800px) { width: 50%; }
        @media screen and (max-width: 600px) { width: 100%; }
    }

    .c-fast-link-card {
        background-color: rgb(var(--rgb-light-blue));
    }

    &.--home &__close-button {
        display: none;
    }

    &__close-button {
        @extend .c-button;
        @extend .c-button--cyan-to-marine;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-stroke;
        position: absolute;
        bottom: 20px;
        left: 50%;
        translate: -50% 0;
        opacity: 0;
        white-space: nowrap;

        @media screen and (min-width: 1201px) { display: none; }

        svg {
            rotate: 0deg;
            stroke-width: 3px;
        }
    }
}

.show-overlay-fast-links {
    .c-overlay-fast-links {
        pointer-events: all;
        visibility: visible;

        &__container {
            translate: 0;
        }

        &__close-button {
            opacity: 1;
            transition: opacity 500ms 900ms;
        }
    }

    body {
        --alert-height: 0px;
    }

    .c-header {
        &__button-fast-links {
            svg {
                &:nth-child(2) {
                    opacity: 0;
                    pointer-events: none;
                }

                &:last-child {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    .c-button-fastlinks {
        z-index: 30;

        svg {
            &:nth-child(2) {
                opacity: 0;
            }
            &:last-child {
                opacity: 1;
            }
        }
    }
}

.show-overlay-fast-links:not(.closing-overlay-fast-links) {
    .c-overlay-fast-links {
        z-index: 25;

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }
}

.show-overlay-fast-links.closing-overlay-fast-links {
    .c-overlay-fast-links {
        &__container {
            translate: 0 -100%;
        }

        &__close-button {
            opacity: 0;
            transition: opacity 300ms 0ms;
        }
    }
}