@use "../base/typography";

.c-header {
    position: relative;
    z-index: 30;
    height: calc(var(--header-height) + var(--alert-height));
    transition-property: height;
    transition-duration: var(--alert-transition-duration);
    transition-timing-function: var(--alert-transition-timing-function);
    transition-delay: var(--alert-transition-delay);

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding-top: calc(22px + var(--alert-height));
        padding-right: var(--x-40);
        padding-bottom: 22px;
        padding-left: var(--x-40);
        background-color: rgb(var(--rgb-white));
        border-bottom: 1px solid rgba(var(--rgb-marine), 0.25);
        transition-property: padding-top;
        transition-duration: var(--alert-transition-duration);
        transition-timing-function: var(--alert-transition-timing-function);
        transition-delay: var(--alert-transition-delay);

        @media screen and (max-width: 500px) {
            padding-top: calc(30px + var(--alert-height));
            padding-bottom: 15px;
        }
    }

    &.--home &__container {
        border: none;
    }

    &__home-link {
        display: block;
    }

    &__home-logo {
        width: 258px;

        @media screen and (max-width: 500px) { width: 200px; }
        @media screen and (max-width: 400px) { width: 170px; }
    }

    &__center {
        @media screen and (max-width: 1200px) { display: none; }
    }

    &__list {
        display: flex;
        align-items: center;
    }

    &__list-item:not(:last-child) {
        margin-right: var(--x-50);
    }

    &__dropdown-button {
        span {
            padding-bottom: 3px;
            font-family: var(--ff-1);
            font-size: var(--fz-17);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-cyan), 0);
            transition: box-shadow 300ms var(--out-cubic);
        }

        &.is-active span {
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-cyan), 1);
        }

        &:hover span {
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-cyan), 1);
        }
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__button-search {
        position: relative;
        margin-right: 30px;

        @media screen and (max-width: 500px) { margin-right: 20px; }

        svg {
            width: 22px;
            height: 22px;
            transition: fill 300ms, opacity 300ms, stroke 300ms;

            @media screen and (max-width: 500px) {
                width: 16px;
                height: 16px;
            }

            &:first-child {
                stroke: none;
                fill: rgb(var(--rgb-marine));
            }

            &:last-child {
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                stroke: rgb(var(--rgb-marine));
                stroke-width: 2px;
                fill: none;
                opacity: 0;
                pointer-events: none;
            }
        }

        &:hover svg {
            fill: rgb(var(--rgb-cyan));
            stroke: rgb(var(--rgb-cyan));
        }
    }

    &__button-fast-links {
        @extend .c-button;
        @extend .c-button--cyan-to-marine;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-stroke;
        position: relative;

        @media screen and (max-width: 1200px) { display: none; }

        svg {
            rotate: 0deg;
            stroke-width: 3px;
            transition: opacity 300ms;

            &:last-child {
                position: absolute;
                top: 50%;
                right: 30px;
                translate: 0 -50%;
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .c-button-hamburger {
        @media screen and (min-width: 1201px) { display: none; }
    }
}

.show-overlay  {
    .c-header,
    .c-header.--home .c-header__container {
        transition-delay: 0ms;
    }
}