.o-layout {
    display: flow-root;
    position: relative;

    &.--page-publications .c-button-calendar {
        display: none;
    }

    &.--page-events {
        background-color: rgb(var(--rgb-light-blue));
    }
}