.c-button-hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-cyan));
    transition: background-color 300ms;

    &:hover {
        background-color: rgb(var(--rgb-marine));
    }

    &__lines {
        position: relative;
        width: 16px;
        height: 12px;
    }

    &__line {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 5px;
        background-color: rgb(var(--rgb-white));
        transition: translate 400ms var(--out-cubic), rotate 400ms, background-color 300ms;

        &:first-child {
            top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 50%;
            translate: 0 -50%;
        }
        &:last-child {
            bottom: 0;
        }
    }
}