@use "../base/typography";

.c-search {
    .c-search-field {
        position: relative;
        width: 100%;

        .c-search-field__input-container {
            width: 100%;
            height: 100%;
        }

        .c-search-field__input {
            width: 100%;
            height: 100%;
            padding: 17px 220px 17px 35px;
            border: none;
            border-radius: 50px;
            box-shadow: 0 0 0 100px rgb(var(--rgb-light-blue)) inset;
            background-color: rgb(var(--rgb-light-blue));

            font-family: var(--ff-1);
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));

            @media screen and (max-width: 600px) {
                padding: 13.5px 90px 13.5px 35px;
                font-size: 1.4rem;
            }

            &::-webkit-input-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &::-moz-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &:-ms-input-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &:-moz-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &::placeholder { color: rgba(var(--rgb-marine), 0.5); }
        }

        .c-search-field__search-button {
            @extend .c-button;
            @extend .c-button--cyan-to-marine;
            @extend .c-button--svg-on-the-right;
            @extend .c-button--svg-fill;
            position: absolute;
            top: 50%;
            right: -1px;
            translate: 0 -50%;

            @media screen and (max-width: 600px) {
                justify-content: center;
                width: 46px;
                height: 46px;
                padding: 0;
                border-radius: 50%;
            }

            span {
                @media screen and (max-width: 600px) { display: none; }
            }

            svg {
                rotate: 0deg;

                @media screen and (max-width: 600px) {
                    translate: 0 0;
                    margin-left: 0;
                }
            }
        }

        .c-search-field__x {
            position: absolute;
            top: 50%;
            right: 190px;
            translate: 0 -50%;
            display: block;
            width: 16px;
            height: 16px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;

            @media screen and (max-width: 700px) { right: 60px; }

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-marine));
                transition: opacity 300ms;
            }
        }

        &[data-inputvalue]:not([data-inputvalue=""]) .c-search-field__x {
            opacity: 1;
            pointer-events: all;
            transition: opacity 300ms;

            &:hover svg {
                opacity: 0.6;
            }
        }
    }
}