@use "../base/typography";

.c-showcase {
    --showcase-height: 550px;
    @media screen and (max-width: 1000px) { --showcase-height: 450px; }
    @media screen and (max-width: 800px) { --showcase-height: 350px; }
    @media screen and (max-width: 600px) { --showcase-height: 0; }
}

.c-showcase {
    &__container {
        display: flex;
        width: 100%;
        min-height: var(--showcase-height);

        @media screen and (max-width: 600px) { flex-direction: column; }
    }

    &__picture-container {
        flex: 1;
        min-height: var(--showcase-height);

        @media screen and (max-width: 600px) { flex: initial; }
    }

    &__picture {
        position: relative;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 600px) {
            height: initial;
            padding-top: 57%;
        }
    }

    &__img {
        @media screen and (max-width: 600px) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 480px;
        padding: max(var(--y-100), 50px) var(--x-50);
        background-color: rgb(var(--rgb-marine));

        @media screen and (max-width: 1000px) { width: 380px; }
        @media screen and (max-width: 800px) { width: 300px; }
        @media screen and (max-width: 600px) { width: 100%; }
    }

    &__surtitle {
        display: inline-block;
        margin-bottom: 40px;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.4em;
        color: rgba(var(--rgb-white), 0.7);
    }

    &__title {
        margin-bottom: 40px;
        font-size: max(var(--fz-30), 2.2rem);
        font-weight: 700;
        line-height: 1.4em;
        color: rgb(var(--rgb-white));
    }

    &__button {
        @extend .c-button;
        @extend .c-button--empty-to-white;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;
    }
}

.o-layout.--page-home {
    .c-showcase {
        background-color: rgb(var(--rgb-light-blue));
    }
}