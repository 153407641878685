/*
|--------------------------------------------------------------------------
| Les Font-face
|--------------------------------------------------------------------------
|
| La déclaration des polices de caractères.
|
| 100: Thin (Hairline)
| 200: Extra Light (Ultra)
| 300: Light
| 400: Normal (Regular, Book)
| 500: Medium
| 600: Semi Bold (Demi)
| 700: Bold
| 800: Extra Bold (Ultra)
| 900: Black (Heavy)
|
*/

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');
@import url("https://use.typekit.net/svh3evq.css");