@use "../../abstract/placeholders";

.c-rubric-card {
    position: relative;
    background-color: rgba(var(--rgb-light-blue), 0.5);
    -webkit-tap-highlight-color: transparent;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &__header {
        position: relative;
        display: flex;
        align-items: flex-end;
        min-height: 125px;
        padding: 25px 30px;
        background-color: rgb(var(--rgb-light-blue));
        cursor: pointer;

        @media screen and (max-width: 768px)  { min-height: 100px; }
        @media screen and (max-width: 400px) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    &__picture {
        @extend %border-radius;
        overflow: hidden;
        flex-shrink: 0;
        width: 105px;
        height: 105px;
        border-radius: 50%;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__text {
        flex: 1;
        padding-right: 50px;
    }

    &__surtitle {
        display: block;
        margin-bottom: 10px;
        font-size: var(--fz-14);
        font-weight: 600;
        line-height: 1.6em;
        color: rgba(var(--rgb-marine), 0.7);
        transition: color 300ms;
    }

    &__header:hover &__surtitle {
        color: rgb(var(--rgb-cyan));
    }

    &.js-accordion-opened &__surtitle {
        color: rgb(var(--rgb-cyan));
    }

    &__title {
        font-family: var(--ff-2);
        font-size: var(--fz-25);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;
    }

    &__header:hover &__title {
        color: rgb(var(--rgb-cyan));
    }

    &.js-accordion-opened &__title {
        color: rgb(var(--rgb-cyan));
    }

    &__plus-container {
        position: absolute;
        right: 25px;
        bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-cyan));

        @media screen and (max-width: 400px) {
            right: 15px;
            width: 30px;
            height: 30px;
        }
    }

    &__plus {
        width: 15px;
        height: 15px;

        &::before,
        &::after {
            height: 2px;
            border-radius: 50px;
            background-color: rgb(var(--rgb-white));
            opacity: 1;
            transition-property: background-color, opacity;
            transition-duration: 300ms;
        }
    }

    &__header:hover &__plus {
        &::before,
        &::after {
            background-color: rgb(var(--rgb-white));
        }
    }

    &.js-accordion-opened &__plus {
        &::before,
        &::after {
            background-color: rgb(var(--rgb-white));
        }

        &::after {
            opacity: 0;
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
        will-change: height;
    }

    &__transition {
        opacity: 0;
        padding-top: 50px;
        padding-right: 30px;
        padding-left: 30px;
        transform: translateY(100px);
        transition-property: opacity, transform;
        transition-duration: 700ms;
        transition-timing-function: var(--in-out-quart);

        @media screen and (max-width: 400px) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    &.js-accordion-opened &__transition {
        opacity: 1;
        transform: none;
        transition-delay: 100ms;
    }

    &__transition > :first-child {
        margin-top: 0;
    }

    &__cms-content,
    &__documents,
    &__gallery {
        padding-bottom: var(--y-60);
    }

    &__share-container {
        padding-bottom: 25px;
    }

    &__share-button {
        display: flex;
        align-items: center;

        span {
            display: inline-block;
            margin-right: 10px;
            font-family: var(--ff-1);
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-cyan));
        }

        svg {
            width: 12px;
            height: 12px;
            fill: rgb(var(--rgb-marine));
            transition: fill 300ms;
        }

        &:hover svg {
            fill: rgb(var(--rgb-cyan));
        }
    }

    &:not(.js-accordion-opened) {
        iframe {
            pointer-events: none;
        }
    }
}
