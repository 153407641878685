.c-alerts {
    --nav-width: 94px;
    @media screen and (max-width: 700px) { --nav-width: 42px; }

    --left-section-width: 46px;
    @media screen and (max-width: 500px) { --left-section-width: 46px; }

    --right-section-width: 85px;
    @media screen and (max-width: 600px) { --right-section-width: 35px; }

    &.--with-nav {
        --left-section-width: calc(76px + var(--nav-width));
        @media screen and (max-width: 500px) { --left-section-width: 46px; }
    }
}

.c-alerts {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 35;
    overflow: hidden;
    height: var(--alert-height);
    transition-property: height;
    transition-duration: var(--alert-transition-duration);
    transition-timing-function: var(--alert-transition-timing-function);
    transition-delay: var(--alert-transition-delay);

    &__container {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: rgb(var(--rgb-red));
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: var(--left-section-width);
        height: 100%;
        padding-left: 25px;
    }

    &__svg-container {
        flex-shrink: 0;
        width: 20px;
        height: 20px;

        svg {
            width: 100%;
            height: 100%;
            fill: rgb(var(--rgb-white));
        }
    }

    &__swiper-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: var(--nav-width);

        @media screen and (max-width: 500px) { display: none; }
    }

    &__swiper-pagination {
        translate: 0 1px;
        display: block;

        font-size: 1.3rem;
        font-weight: 700;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 700px) { display: none; }
    }

    &__arrows-box {
        display: flex;
        align-items: center;
    }

    &__arrow-prev,
    &__arrow-next {
        flex-shrink: 0;
        font-size: 0;
        transition: opacity 300ms;

        &:hover { opacity: 0.5; }
    }

    &__arrow-prev svg {
        rotate: 90deg;
        margin-right: 12px;
    }

    &__arrow-next svg {
        rotate: -90deg;
    }

    &__svg-arrow {
        width: 15px;
        height: 15px;
        fill: rgb(var(--rgb-white));
    }

    &__center {
        width: calc(100% - var(--left-section-width) - var(--right-section-width));
        height: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }

    &__swiper {
        overflow: hidden;
        height: 100%;
    }

    .swiper,
    .swiper-wrapper,
    .swiper-slide {
        height: 100%!important;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__item,
    &__link {
        display: flex;
        align-items: center;
        max-width: 100%;
    }

    &__item-text,
    &__link-text {
        overflow: hidden;

        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.6em;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgba(var(--rgb-white));

        @media screen and (max-width: 400px) { font-size: 1.2rem; }
    }

    &__link-text {
        transition: color 0.3s;
    }

    &__link:hover &__link-text {
        color: rgba(var(--rgb-white), 0.6);
    }

    &__right {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: var(--right-section-width);
        height: 100%;
        padding-right: 25px;
    }

    &__close-button {
        display: flex;
        align-items: center;
        height: 100%;
        transition: opacity 300ms;

        svg {
            width: 10px;
            height: 10px;
            stroke: rgb(var(--rgb-white));
        }

        &:hover {
            opacity: 0.5;
        }
    }

    &__close-button-text {
        display: inline-block;
        margin-right: 6px;

        font-family: var(--ff-1);
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 600px) { display: none; }
    }
}

.show-overlay .c-alerts {
    transition-delay: 0ms;
}