@use "../../abstract/placeholders";

.c-event-card {
    &__container,
    &__top-section {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__container {
        flex-direction: column;
        height: 100%;
        min-height: 550px;
        padding: max(var(--x-40), 25px);
        background-color: rgb(var(--rgb-white));
        border-top-right-radius: clamp(65px, 7vw, 100px);

        @media screen and (max-width: 1400px) { min-height: 500px; }
        @media screen and (max-width: 1300px) { min-height: 450px; }
        @media screen and (max-width: 768px) { min-height: 400px; }
        @media screen and (max-width: 700px) { min-height: 365px; }
    }

    &__top-section {
        align-items: flex-start;
    }

    &__date {
        display: inline-block;

        span {
            font-family: var(--ff-2);
            font-weight: 700;
            line-height: 1.2em;
            color: rgb(var(--rgb-cyan));

            &:first-child {
                font-size: var(--fz-35);

                @media screen and (max-width: 400px) { font-size: max(var(--fz-25), 2.2rem); }
            }

            &:last-child {
                font-size: var(--fz-25);

                @media screen and (max-width: 400px) { font-size: 1.7rem; }
            }
        }
    }

    &__picture-container {
        @extend %border-radius;
        overflow: hidden;
        flex-shrink: 0;
        width: 160px;
        height: 160px;
        border-radius: 50%;

        @media screen and (max-width: 1400px) {
            width: 140px;
            height: 140px;
        }
        @media screen and (max-width: 1300px) {
            width: 120px;
            height: 120px;
        }
        @media screen and (max-width: 768px) {
            width: 100px;
            height: 100px;
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__bottom-section {
        margin-top: var(--y-65);
    }

    &__category {
        display: inline-block;
        margin-bottom: 20px;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.4em;
        color: rgba(var(--rgb-marine), 0.7);
        transition: color 300ms;

        @media screen and (max-width: 400px) {
            margin-bottom: 15px;
            font-size: 1.2rem;
        }
    }

    &:hover &__category {
        color: rgb(var(--rgb-cyan));
    }

    &__title {
        font-family: var(--ff-2);
        font-size: max(var(--fz-22), 1.7rem);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-cyan));
    }

    &__infos {
        margin-top: 40px;
    }

    &__info-date,
    &__place {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 12px;

            @media screen and (max-width: 400px) { margin-bottom: 6px; }
        }

        svg {
            width: 18px;
            height: 18px;
            margin-right: 12px;
            stroke: rgb(var(--rgb-cyan));
            stroke-width: 2px;

            @media screen and (max-width: 400px) {
                width: 12px;
                height: 12px;
            }
        }

        span {
            padding-top: 3px;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1.4em;
            color: rgba(var(--rgb-marine), 0.7);

            @media screen and (max-width: 400px) { font-size: 1.2rem; }
        }
    }
}