.c-form-label {
    display: block;
    margin-bottom: 12px;
    font-size: var(--fz-17);
    font-weight: 500;
    line-height: 1.1em;
    color: rgb(var(--rgb-marine));

    &--radio-and-checkbox {
        display: block;
        margin-bottom: 5px;
        font-family: var(--ff-1);
        font-size: var(--fz-17);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-marine));
    }
}

.c-form-required-dot {
    display: inline;
    color: rgb(var(--rgb-red));
    vertical-align: super;
    margin-bottom: 0.2em;
}
