@use "../../abstract/placeholders";

.c-large-document-card {
    position: relative;
    display: flex;
    align-items: flex-end;
    min-height: 125px;
    padding: 25px 30px;
    background-color: rgb(var(--rgb-light-blue));
    cursor: pointer;

    @media screen and (max-width: 768px)  { min-height: 100px; }

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 70px;
    }

    &.--job-document {
        align-items: flex-end;
        min-height: 150px
    }

    &__picture {
        @extend %border-radius;
        overflow: hidden;
        flex-shrink: 0;
        width: 105px;
        height: 105px;
        border-radius: 50%;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__content {
        flex: 1;
        padding-right: 50px;
    }

    &__surtitle {
        display: block;
        margin-bottom: 10px;
        font-size: var(--fz-14);
        font-weight: 600;
        line-height: 1.6em;
        color: rgba(var(--rgb-marine), 0.7);
        transition: color 300ms;
    }

    &:hover &__surtitle {
        color: rgb(var(--rgb-cyan));
    }

    &__title {
        font-family: var(--ff-2);
        font-size: var(--fz-25);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-cyan));
    }

    &__svg-container {
        position: absolute;
        right: 25px;
        bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-cyan));
        font-size: 0;
    }

    &__svg {
        width: 15px;
        height: 15px;
        fill: rgb(var(--rgb-white));

        &.--job {
            display: none;
        }

        &.--arrow {
            rotate: 90deg;
            width: 14px;
            height: 14px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
            fill: none;
        }
    }
}
