/*
|--------------------------------------------------------------------------
| Normalize
|--------------------------------------------------------------------------
|
| Les styles appliqués globalement au projet.
|
*/

@use "../abstract/variables";

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

html {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: var(--ff-1);
    font-size: variables.$base-font-size;
    color: rgb(var(--rgb-marine));
    vertical-align: baseline;
}

body {
    position: relative;
    font-family: var(--ff-1);
    line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--ff-2);
    font-weight: normal;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;

    &::before,
    &::after {
        content: '';
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

abbr[title], dfn[title] {
    text-decoration: none;
    cursor: help;
}

del {
    text-decoration: line-through;
}

a {
    font-family: var(--ff-1);
    color: rgb(var(--rgb-marine));
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

pre {
    font-family: var(--ff-1);
    color: rgb(var(--rgb-note));
    white-space: normal;
}

input {
    vertical-align: middle;
    border-radius: 0;
    -webkit-appearance: none;

    &:focus {
        outline: none;
    }

    &[type="search"]::-ms-clear {  display: none; width : 0; height: 0; }
    &[type="search"]::-ms-reveal {  display: none; width : 0; height: 0; }

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration { display: none; }
}

select {
    vertical-align: middle;
    -webkit-appearance: none;
}

picture {
    --position : center;

    display: block;
    font-size: 0;

    img {
        width: 100%;
        height: 100%;
        object-position: var(--position);
    }

    .cover {
        object-fit: cover;
    }

    .contain {
        object-fit: contain;
    }

}

.slick-slide,
.slick-slide a,
.slick-slide:focus { // Retirer le outline d'un slick slider
    outline: none !important;
}

.slick-loading .slick-list { // Retirer les images introuvables du slick slide pour éviter une 404
    background: initial;
}

.fancybox-caption__body {
    font-family: var(--ff-1);
}

.first-loading {
    display: none;
}

button {
    appearance: none;
    color: initial;
    background: initial;
    border: initial;
    font: initial;
    border-radius: initial;
    padding: initial;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
