@use "../base/typography";

.c-footer {
    &__flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: max(var(--y-50), 45px) var(--x-60);
        background-color: rgb(var(--rgb-light-blue));
        border-bottom: 1px solid rgba(var(--rgb-marine), 0.25);

        @media screen and (max-width: 900px) {
            flex-direction: column;
            justify-content: initial;
        }
    }

    &__left {
        @media screen and (max-width: 900px) { margin-bottom: 35px; }
    }

    &__home-logo {
        width: 258px;
        height: 55px;

        @media screen and (max-width: 1000px) {
            width: 208px;
            height: 46px;
        }
    }

    &__form,
    &__form-input-container {
        display: flex;
        align-items: center;

        @media screen and (max-width: 900px) { flex-direction: column; }
    }

    &__form {
        @media screen and (max-width: 600px) { width: 100%; }
    }

    &__form-label {
        margin-right: 20px;
        font-family: var(--ff-1);
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));

        @media screen and (max-width: 900px) {
            display: none;
            margin-right: 0;
            margin-bottom: 15px;
        }

        &.--mobile {
            display: none;

            @media screen and (max-width: 900px) { display: block; }
        }
    }

    &__form-input-container {
        position: relative;
        width: 450px;

        @media screen and (max-width: 600px) { width: 100%; }
    }

    &__form-input {
        width: 100%;
        padding: 18px 185px 19px 35px;
        border-radius: 50px;
        border: none;
        background-color: rgb(var(--rgb-white));
        font-family: var(--ff-1);
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;

        @media screen and (max-width: 900px) {
            padding: 14px 80px 14px 35px;
            font-size: 1.4rem;
        }
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: rgb(var(--rgb-note)); }
    ::-moz-placeholder { color: rgb(var(--rgb-note)); }
    :-ms-input-placeholder {  color: rgb(var(--rgb-note)); }
    :-moz-placeholder { color: rgb(var(--rgb-note)); }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        font-size: 1.5rem !important;
        -webkit-text-fill-color: rgb(var(--rgb-marine));
        caret-color: rgb(var(--rgb-marine));

        @media screen and (max-width: 900px) { font-size: 1.4rem !important; }
    }

    :-webkit-autofill::first-line {
        font-family: var(--ff-1);
        font-size: 1.5rem;

        @media screen and (max-width: 900px) { font-size: 1.4rem; }
    }

    &__form-error {
        margin-top: 8px;
        position: absolute;
        top: 100%;
        left: 0;
        font-family: var(--ff-1);
        font-size: 1.4rem;
        font-weight: 500;
        color: rgb(var(--rgb-red));

        @media screen and (max-width: 900px) { font-size: 1.3rem; }
    }

    &__form-button {
        @extend .c-button;
        @extend .c-button--cyan-to-marine;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-stroke;

    }

    &__copyright,
    &__copyright-left,
    &__copyright-right,
    &__copyright-link,
    &__button-scroll-to-top {
        display: flex;
        align-items: center;
    }

    &__copyright {
        width: 100%;
        height: var(--footer-copyright-height);
        padding: 30px var(--x-60);
        justify-content: space-between;

        @media screen and (max-width: 600px) {
            display: none;
            flex-direction: column;
            justify-content: initial;
        }

        &.--mobile {
            display: none;

            @media screen and (max-width: 600px) { display: flex; }
        }
    }

    &__copyright-left {
        @media screen and (max-width: 600px) { margin-bottom: 20px; }
    }

    &__copyright-right {
        @media screen and (max-width: 600px) { flex-direction: column; }
    }

    &__copyright-item,
    &__copyright-link span,
    &__button-scroll-to-top span {
        font-family: var(--ff-1);
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));
    }

    &__copyright-item {
        display: inline-block;
        margin-right: 20px;

        @media screen and (max-width: 600px) { margin-right: 0; }
    }

    &__copyright-link {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        svg {
            flex-shrink: 0;
            width: 17px;
            height: 17px;
            margin-left: 10px;
            fill: rgb(var(--rgb-cyan));
        }
    }

    &__copyright-link + &__copyright-link {
        margin-left: 20px;
    }

    &__button-scroll-to-top {
        margin-left: var(--x-90);
        transition: opacity 300ms;

        @media screen and (max-width: 600px) { margin-top: 15px; }

        &:hover {
            opacity: 0.6;
        }

        svg {
            flex-shrink: 0;
            width: 15px;
            height: 15px;
            margin-left: 6px;
            stroke: rgb(var(--rgb-marine));
            stroke-width: 1.75px;
        }
    }
}