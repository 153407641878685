.c-filter {
    --navigation-width: 80px;
    @media screen and (max-width: 600px) { --navigation-width: 100%; }
}

.c-filter {
    position: relative;
    overflow: hidden;
    padding-right: var(--x-60);
    padding-left: var(--x-60);
    background-color: rgb(var(--rgb-white));
    border-bottom: 1px solid rgba(var(--rgb-marine), 0.25);

    @media screen and (max-width: 900px) { position: initial; }

    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100px;

        @media screen and (max-width: 600px) { height: 80px; }
    }

    &__swiper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 2;
            pointer-events: none;
            transition: opacity 200ms;
        }

        &::before {
            left: -35px;
            width: 80px;
            background: linear-gradient(90deg, rgba(var(--rgb-white), 1) 50%, rgba(255, 255, 255, 0) 75%);
        }

        &.--no-prev::before {
            opacity: 0;
        }

        &::after {
            right: 0;
            width: calc(var(--navigation-width) + 80px);
            background: linear-gradient(270deg, rgba(var(--rgb-white),1) 45%, rgba(255,255,255,0) 80%);

            @media screen and (max-width: 600px) {
                right: -35px;
                width: 80px;
            }
        }

        &.--no-next::after {
            opacity: 0;
        }
    }

    .swiper {
        margin-right: 0;
        margin-left: 0;
    }

    .swiper-slide {
        width: fit-content;

        &:last-child {
            @media screen and (min-width: 601px) { margin-right: var(--navigation-width)!important; }
        }
    }

    &__button {
        display: block;

        span {
            display: block;
            padding-bottom: 3px;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));
            box-shadow: inset 0 -0.15em 0 0 rgba(var(--rgb-cyan), 0);
            transition: color 300ms, box-shadow 300ms;
        }

        &.--active span {
            box-shadow: inset 0 -0.15em 0 0 rgb(var(--rgb-cyan));
        }

        &:hover span {
            color: rgb(var(--rgb-note));
        }
    }

    &__swiper-navigation {
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 5;
        translate: 0 -50%;
        width: var(--navigation-width);
        height: 50px;
        transition: opacity 300ms, visibility 0ms 0ms;
        pointer-events: none;

        &.--hidden {
            opacity: 0;
            visibility: hidden;
            transition: opacity 300ms, visibility 300ms 0ms;
        }
    }

    &__swiper-navigation-container {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 39px;
        background-color: rgb(var(--rgb-white));

        @media screen and (max-width: 600px) {
            padding: 0;
            border-radius: 0;
            background-color: transparent;
        }
    }

    &__swiper-arrow-prev,
    &__swiper-arrow-next {
        width: 14px;
        height: 14px;
        transition: opacity 300ms, visibility 0ms 0ms;

        @media screen and (max-width: 600px) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: rgb(var(--rgb-white));
            pointer-events: all;
        }

        svg {
            width: 100%;
            height: 100%;
            stroke: rgb(var(--rgb-marine));

            @media screen and (max-width: 600px) {
                width: 14px;
                height: 14px;
            }
        }
    }

    &__swiper-arrow-prev svg {
        rotate: 90deg;
    }

    &__swiper-arrow-next svg {
        rotate: -90deg;
    }

    .swiper-button-disabled {
        opacity: 0.6;
        pointer-events: none;

        @media screen and (max-width: 600px) { opacity: 0; }
    }

    .swiper-button-lock {
        display: block!important;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 300ms, visibility 300ms 0ms;
    }

    .c-button-calendar {
        position: absolute;
        top: 50%;
        right: var(--x-100);
        z-index: 5;
        translate: 0 -50%;

        @media screen and (max-width: 1200px) { right: var(--x-80); }
        @media screen and (max-width: 1024px) { right: var(--x-50); }
        @media screen and (max-width: 900px) { display: none; }
    }
}

.o-layout.--page-events {
    .c-filter {
        padding-right: var(--x-180);
        padding-left: var(--x-180);
    }
}