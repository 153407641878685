/*
|--------------------------------------------------------------------------
| Les variables de Font-families
|--------------------------------------------------------------------------
|
| Déclaration des variables de font-families du projet
|
*/

:root {
    --ff-1: 'Manrope', sans-serif;
    --ff-2: 'area-normal', sans-serif;
}
