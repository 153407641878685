@use "../../base/typography";

.c-overlay-search {
    position: fixed;
    top: calc(var(--header-height) + var(--alert-height));
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
    transition-property: top;
    transition-duration: var(--alert-transition-duration);
    transition-timing-function: var(--alert-transition-timing-function);
    transition-delay: var(--alert-transition-delay);

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 700ms var(--in-out-quart);
        z-index: -1;
        cursor: default;
    }

    &__container {
        overflow-y: auto;
        overflow-x: hidden;
        translate: 0 -100%;
        max-height: calc((var(--vh, 1vh) * 100) - var(--alert-height) - var(--header-height));
        padding-top: max(var(--y-60), 35px);
        padding-right: var(--x-240);
        padding-bottom: max(var(--y-60), 35px);
        padding-left: var(--x-240);
        background-color: rgb(var(--rgb-white));
        transition: translate 900ms var(--in-out-quart);
    }

    .c-search-field {
        position: relative;
        width: 100%;

        .c-search-field__input-container {
            width: 100%;
            height: 100%;
        }

        .c-search-field__input {
            width: 100%;
            height: 100%;
            padding: 13px 220px 24px 0px;
            border: none;
            border-bottom: 2px solid rgb(var(--rgb-cyan));
            border-radius: 0;
            box-shadow: 0 0 0 100px rgb(var(--rgb-white)) inset;
            background-color: rgb(var(--rgb-white));
            font-family: var(--ff-1);
            font-size: 2.2rem;
            font-weight: 600;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));

            @media screen and (max-width: 600px) {
                padding: 0px 60px 10px 0px;
                font-size: 1.7rem;
            }

            &::-webkit-input-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &::-moz-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &:-ms-input-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &:-moz-placeholder { color: rgba(var(--rgb-marine), 0.5); }
            &::placeholder { color: rgba(var(--rgb-marine), 0.5); }
        }

        .c-search-field__search-button {
            @extend .c-button;
            @extend .c-button--cyan-to-marine;
            @extend .c-button--svg-on-the-right;
            @extend .c-button--svg-stroke;
            position: absolute;
            right: -1px;
            bottom: 10px;

            @media screen and (max-width: 600px) { display: none; }
        }

        .c-search-field__search-button-mobile {
            position: absolute;
            top: calc(50% - 5px);
            right: -1px;
            translate: 0 -50%;
            width: 16px;
            height: 16px;

            @media screen and (min-width: 601px) { display: none; }

            svg {
                width: 100%;
                height: 100%;
                fill: rgb(var(--rgb-marine));
                transition: fill 300ms;
            }

            &:hover {
                fill: rgb(var(--rgb-cyan));
            }
        }

        .c-search-field__x {
            position: absolute;
            top: calc(50% - 5px);
            right: 190px;
            translate: 0 -50%;
            display: block;
            width: 16px;
            height: 16px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;

            @media screen and (max-width: 600px) { right: 30px; }

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-marine));
                transition: opacity 300ms;
            }
        }

        &[data-inputvalue]:not([data-inputvalue=""]) .c-search-field__x {
            opacity: 1;
            pointer-events: all;
            transition: opacity 300ms;

            &:hover svg {
                opacity: 0.6;
            }
        }
    }
}

.show-overlay-search {
    .c-overlay-search {
        pointer-events: all;
        visibility: visible;

        &__container {
            translate: 0;
        }
    }

    .c-header {
        &__button-search {
            svg {
                &:first-child {
                    opacity: 0;
                    pointer-events: none;
                }

                &:last-child {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    body {
        --alert-height: 0px;
    }
}

.show-overlay-search:not(.closing-overlay-search) {
    .c-overlay-search {
        z-index: 25;

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }
}

.show-overlay-search.closing-overlay-search {
    .c-overlay-search {
        &__container {
            translate: 0 -100%;
        }
    }
}