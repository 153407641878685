@use "../../base/typography";

// Si ces variables ne sont pas accessibles dans ce site, il suffit de les décommenter pour les utiliser.
/*.c-overlay-cookies {
    --fz-nus-16: max(1.5rem + (1.6 - 1.5) * ((100vw - 50rem) / (144 - 50)), 1.5rem);
    --y-30: max(22px + (30 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
    --y-nus-25: max(20px + (25 - 20) * ((100vw - 500px) / (1440 - 500)), 20px);
    --y-nus-20: max(16px + (20 - 16) * ((100vw - 500px) / (1440 - 500)), 16px);
    --x-30: max(22px + (30 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
    --x-nus-25: max(22px + (25 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
}*/

.c-overlay-cookies {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 110;

    &__container {
        position: absolute;
        bottom: var(--y-30);
        left: var(--x-30);
        width: 450px;
        padding: var(--y-nus-25) var(--x-nus-25);
        background-color: rgb(255, 255, 255);
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.10);
        opacity: 0;
        pointer-events: none;
        transform: translateY(var(--y-30));
        transition: opacity 800ms cubic-bezier(0.76, 0, 0.24, 1), transform 800ms cubic-bezier(0.76, 0, 0.24, 1);
        will-change: transform;

        @media screen and (max-width: 500px) {
            width: calc(100% - (var(--x-30) * 2));
        }
    }

    &__title {
        font-size: var(--fz-nus-16);
        font-weight: 700;
        line-height: 1.6em;
    }

    &__text {
        display: block;
        font-size: 1.4rem;
        line-height: 1.6em;
        margin-top: 7px;
    }

    &__buttons {
        display: flex;
        margin-top: var(--y-nus-20);

        @media screen and (max-width: 450px) {
            flex-direction: column;
        }
    }

    &__button-find-out-more,
    &__button-accept {
        width: 50%;
        justify-content: center;
        padding-top: 14px;
        padding-right: 30px;
        padding-bottom: 14px;
        padding-left: 30px;

        @media screen and (max-width: 450px) {
            width: 100%;
        }
    }

    &__button-find-out-more {
        @extend .c-button;
        @extend .c-button--empty-to-marine;
        margin-right: 10px;

        @media screen and (max-width: 450px) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__button-accept {
        @extend .c-button;
        @extend .c-button--cyan-to-marine;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-cookies {
    .c-overlay-cookies {
        visibility: visible;

        &__container {
            opacity: 1;
            pointer-events: all;
            transform: none;
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-cookies.closing-overlay-cookies {
    .c-overlay-cookies {
        &__container {
            opacity: 0;
            transform: translateY(var(--y-30));
        }
    }
}
