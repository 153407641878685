.c-button-share {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-cyan));
    transition: background-color 300ms;

    &:hover {
        background-color: rgb(var(--rgb-marine));
    }

    svg {
        width: 12px;
        height: 12px;
        fill: rgb(var(--rgb-white));
        transition: fill 300ms;
    }
}
