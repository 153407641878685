@use "../../base/typography";

.c-overlay-menu {
    --back-button-height: 20px;
}

.c-overlay-menu {
    position: fixed;
    top: calc(var(--header-height) + var(--alert-height));
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
    z-index: 20;
    transition-property: top;
    transition-duration: var(--alert-transition-duration);
    transition-timing-function: var(--alert-transition-timing-function);
    transition-delay: var(--alert-transition-delay);

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 700ms var(--in-out-quart);
        z-index: -1;
        cursor: default;
    }

    &__container {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 30px;
        position: absolute;
        top: 0;
        left: 100%;
        overflow: hidden;
        background-color: rgb(var(--rgb-white));
        transition-property: left,;
        transition-duration: 1000ms;
        transition-timing-function: var(--in-out-quart);
    }

    &__scrollbar-container {
        width: 100%;
    }

    &__scrollbar {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--rgb-marine), 0.25);
            border-radius: 10px;
        }
    }

    &__content {
        padding: 0 var(--x-60);
        opacity: 0;
        transform: translate3d(100px, 0, 0);
        transition-property: opacity, transform;
        transition-duration: 1000ms;
        transition-timing-function: var(--out-quart);
        transition-delay: 200ms;
    }

    &__interfaces {
        position: relative;
        transition-property: height;
        transition-duration: 1000ms;
        transition-timing-function: var(--in-out-quart);
    }

    &__interface-container {
        width: 100%;
        height: 0px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        transition-property: height;
        transition-duration: 1ms;
        transition-delay: 1000ms;
    }

    &__interface-container:has(.is-active) {
        transition-delay: 0ms;
        height: 100%;
    }

    &__interface {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        pointer-events: none;

        &.is-active {
            transform: none;
            opacity: 1;
            pointer-events: all;
        }

        &.is-on-the-right {
            transform: translate3d(150%, 0, 0);
        }

        &.is-on-the-left {
            transform: translate3d(-150%, 0, 0);
        }

        &.has-animation {
            transition-property: opacity, transform;
            transition-duration: 700ms;
            transition-timing-function: var(--in-out-quart);
        }
    }

    &__primary-buttons-list {
        padding-top: calc(30px + var(--back-button-height));
    }

    &__primary-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 280px;
        min-width: 0;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 2px solid rgb(var(--rgb-cyan));
        text-align: left;

        span {
            display: inline-block;
            margin-right: 15px;
            font-family: var(--ff-2);
            font-size: 1.7rem;
            font-weight: 600;
            line-height: 1.3em;
            color: rgb(var(--rgb-marine));
            transition: color 300ms;
        }

        svg {
            rotate: 90deg;
            flex-shrink: 0;
            width: 16px;
            height: 14px;
            stroke: rgb(var(--rgb-cyan));
            stroke-width: 1.5px;
        }
    }

    &__primary-button {
        &:hover span {
            color: rgb(var(--rgb-cyan));
        }
    }

    &__back-button {
        display: flex;
        align-items: center;
        height: var(--back-button-height);
        margin-bottom: 30px;

        svg {
            rotate: -90deg;
            flex-shrink: 0;
            width: 12px;
            height: 12px;
            margin-right: 12px;
            stroke: rgb(var(--rgb-cyan));
            stroke-width: 2px;
        }

        span {
            font-family: var(--ff-1);
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-marine));
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-cyan));
        }
    }

    &__title {
        width: 100%;
        max-width: 280px;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 25px;
        border-bottom: 2px solid rgb(var(--rgb-cyan));
        text-align: left;
        font-family: var(--ff-2);
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;
    }

    &__secondary-buttons-list {
        margin-bottom: 55px;
    }

    &__secondary-buttons-list-item:not(:last-child) {
        margin-bottom: 10px;
    }

    &__secondary-button {
        font-family: var(--ff-1);
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-cyan));
        }
    }

    &__links-list-item:not(:last-child) {
        margin-bottom: 12px;
    }

    &__link {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-cyan));
        }
    }

    .c-button-fastlinks {
        position: absolute;
    }
}

.show-overlay-menu {
    .c-overlay-menu {
        pointer-events: all;
        visibility: visible;
    }

    body {
        --alert-height: 0px;
    }
}

.show-overlay-menu:not(.closing-overlay-menu) {
    .c-overlay-menu {
        &__container {
            left: 0%;
        }

        &__content {
            opacity: 1;
            transform: none;
            transition-delay: 500ms;
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }

    .c-button-hamburger {
        &__line {
            &:first-child,
            &:last-child {
                opacity: 0;
            }
            &:nth-child(2) {
                rotate: -45deg;
            }
            &:nth-child(3) {
                rotate: 45deg;
            }
        }
    }
}


.show-overlay-menu:not(.closing-overlay-menu) {
    body {
        --alert-height: 0px;
    }
}