@use "sass:math";

// LES TITRES ----------
// Très Gros titre (h1)
.c-huge-title {
    font-size: var(--fz-65);
    font-weight: 700;
    line-height: 1.4em;
}

// Gros titre (h2)
.c-large-title {
    font-size: var(--fz-40);
    font-weight: 700;
    line-height: 1.4em;
}

// Moyen titre (h3)
.c-medium-title {
    font-size: var(--fz-30);
    font-weight: 700;
    line-height: 1.4em;
}

// Petit titre (h4)
.c-small-title {
    font-size: var(--fz-25);
    font-weight: 700;
    line-height: 1.4em;
}


// LES TEXTES ----------
// Le paragraphe
.c-paragraph {
    font-size: min(var(--fz-17), 1.7rem);
    line-height: 1.6em;
}

// L'introduction
.c-introduction  {
    font-size: var(--fz-25);
    font-weight: 500;
    line-height: 1.6em;
}

// La note
.c-note {
    font-size: var(--fz-14);
    line-height: 1.6em;
}

// La citation
.c-quote {
    padding-left: 30px;
    border-left: 10px solid rgba(var(--rgb-marine), 0.25);

    @media screen and (max-width: 1024px) {
        padding-left: 25px;
        border-width: 8px;
    }
    @media screen and (max-width: 768px)  {
        padding-left: 20px;
        border-width: 6px;
    }
    @media screen and (max-width: 500px)  {
        padding-left: 15px;
        border-width: 4px;
    }

    &, p {
        margin: 0;
        font-family: var(--ff-1);
        font-size: var(--fz-22);
        font-weight: 500;
        line-height: 1.5em;
    }
}


// LES LIENS ----------
// Le lien surligné
.c-underlined-link {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.3em;
    text-decoration: none;

    span {
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-cyan));
        transition: color 300ms var(--out-cubic);
    }

    &:hover span {
        color: rgb(var(--rgb-cyan));
    }

    // Icones liens externes et téléchargement
    svg {
        margin-left: math.div(2, 17) + em;
        width: math.div(11, 17) + em;
        height: math.div(11, 17) + em;
        fill: rgb(var(--rgb-marine));
        transition: fill 300ms var(--out-cubic);
    }

    &:hover svg {
        fill: rgb(var(--rgb-cyan));
    }

    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"] {
        svg {
            width: math.div(12, 17) + em;
            height: math.div(12, 17) + em;
        }
    }
}


// LES BOUTONS ----------
// Le bouton plein et vide
.c-button {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 17.5px 30px 17.5px 35px;
    font-family: var(--ff-1);
    font-size: var(--fz-14);
    font-weight: 700;
    line-height: 1.4em;
    color: var(--button-color);
    background-color: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    border-radius: 50px;
    text-decoration: none;
    cursor: pointer;
    transition-property: color, background-color, border;
    transition-duration: 300ms;

    svg {
        --button-svg-spacing: 8px;
        --button-svg-dimension: 13px;
        content: "";
        rotate: 90deg;
        width: var(--button-svg-dimension);
        height: var(--button-svg-dimension);
        flex-shrink: 0;
        display: inline-block;
    }

    &[target="_blank"] svg {
        --button-svg-spacing: 10px;
        --stroke-width: 1px;
        --button-svg-dimension: 11px;
        rotate: 0deg;
    }

    &[href$=".pdf"] svg,
    &[href$=".doc"] svg,
    &[href$=".zip"] svg {
        --button-svg-spacing: 10px;
        --stroke-width: 1px;
        --button-svg-dimension: 12px;
        rotate: 0deg;
    }

    &--svg-on-the-left {
        svg {
            margin-right: var(--button-svg-spacing, 10px);
        }
    }

    &--svg-on-the-right {
        svg {
            margin-left: var(--button-svg-spacing, 10px);
        }
    }

    &--svg-stroke {
        svg {
            --stroke-width: 1.75px;
            stroke: var(--button-svg-color);
            stroke-width: var(--stroke-width, 1px);
            fill: none;
            transition: stroke 300ms;
        }
    }

    &--svg-fill {
        svg {
            fill: var(--button-svg-color);
            stroke: none;
            transition: fill 300ms;
        }
    }

    &--svg-both {
        svg {
            --stroke-width: 1.75px;
            stroke: var(--button-svg-color);
            stroke-width: var(--stroke-width, 1px);
            fill: var(--button-svg-color);
            transition: stroke 300ms, fill 300ms;
        }
    }

    &--cyan-to-marine {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-cyan));
        --button-border-color: rgb(var(--rgb-cyan));

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-marine));
            --button-border-color: rgb(var(--rgb-marine));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }

        &.disabled {
            --button-color: rgb(30, 30, 30);
            --button-background-color: rgb(210, 210, 210);
            --button-border-color: rgb(210, 210, 210);

            svg {
                --button-svg-color: rgb(30, 30, 30);
            }

            &:hover {
                --button-color: rgb(30, 30, 30);
                --button-background-color: rgb(210, 210, 210);
                --button-border-color: rgb(210, 210, 210);
                cursor: not-allowed;

                svg {
                    --button-svg-color: rgb(30, 30, 30);
                }
            }
        }
    }

    &--empty-to-marine {
        --button-color: rgb(var(--rgb-marine));
        --button-background-color: transparent;
        --button-border-color: rgb(var(--rgb-cyan));

        svg {
            --button-svg-color: rgb(var(--rgb-marine));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-marine));
            --button-border-color: rgb(var(--rgb-marine));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }

    &--empty-to-white {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: transparent;
        --button-border-color: rgb(var(--rgb-yellow));

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }

        &:hover {
            --button-color: rgb(var(--rgb-marine));
            --button-background-color: rgb(var(--rgb-white));
            --button-border-color: rgb(var(--rgb-white));

            svg {
                --button-svg-color: rgb(var(--rgb-marine));
            }
        }
    }
}


// LES LISTES ----------
// Liste désordonnée et ordonnée
.c-list {
    li {
        @extend .c-paragraph;

        p {
            margin: 0;
        }

        &:not(:first-child) {
            margin-top: 12px;
        }

        ol,
        ul {
            width: 100%;
            margin-top: 12px;
            margin-bottom: 0;

            li {
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }

    &--unordered {
        --unordered-list-padding: 20px;
        @media screen and (max-width: 500px) { --unordered-list-padding: 15px; }

        & > li {
            width: calc(100% - var(--unordered-list-padding));
            position: relative;
            left: var(--unordered-list-padding);

            &::before {
                content: "";
                width: 6px;
                height: 6px;
                position: absolute;
                top: calc(1.6em / 2);
                transform: translate(calc(0px - var(--unordered-list-padding)), -50%);
                background-color: rgb(var(--rgb-marine));
                border: 1px solid rgb(var(--rgb-marine));
                border-radius: 50%;
                box-sizing: border-box;
            }

            & > ul li {
                &::before {
                    background-color: transparent;
                }

                & > ul li::before {
                    background-color: rgb(var(--rgb-marine));
                }
            }
        }
    }

    &--ordered {
        --ordered-list-padding: 50px;
        @media screen and (max-width: 768px) { --ordered-list-padding: 25px; }
        @media screen and (max-width: 500px) { --ordered-list-padding: 20px; }
        list-style: none;
        counter-reset: li;

        & > li {
            width: calc(100% - var(--ordered-list-padding));
            position: relative;
            left: var(--ordered-list-padding);

            &::before {
                content: counter(li) ".";
                counter-increment: li;
                display: inline-block;
                position: absolute;
                transform: translateX(calc(0px - var(--ordered-list-padding)));
                font-weight: 600;
            }

            ol > li {
                &::before {
                    content: counter(li, lower-alpha) ".";
                }

                ol > li {
                    @media screen and (min-width: 769px) { padding-left: 40px; }

                    &::before {
                        content: counter(li, lower-roman) ".";
                    }
                }
            }
        }
    }
}


// LES TABLEAUX ET ZONES ----------
// Le tableau
.c-table {
    overflow-x: auto;

    table {
        overflow-x: hidden;
        border-collapse: collapse;
        margin-right: 0 !important;

        tr {
            // Applique les styles pour toutes les cellules
            th,
            td {
                @extend .c-paragraph;

                padding: 12px 25px;
                border: 1px solid rgba(var(--rgb-marine), 0.25);

                & > :first-child {
                    margin-top: 0;
                }

                & > *:last-child {
                    margin-bottom: 0 !important;
                }

                @media screen and (max-width: 1024px) { padding: 20px 30px; }
                @media screen and (max-width: 768px)  { padding: 18px 25px; }
                @media screen and (max-width: 500px)  { padding: 16px 20px; }
            }

            // Applique les styles pour les cellules d'en-tête
            th,
            td.table-header {
                background-color: rgb(var(--rgb-marine));
                border-top-color: rgb(var(--rgb-marine));
                border-bottom-color: rgb(var(--rgb-marine));

                font-weight: 600;
                color: rgb(var(--rgb-white));
                text-align: left;

                &:first-child {
                    border-left-color: rgb(var(--rgb-marine));
                }

                &:last-child {
                    border-right-color: rgb(var(--rgb-marine));
                }
            }

            // Applique la couleur de background pour les cellules d'en-tête secondaire
            td.table-secondary-header {
                background-color: rgb(var(--rgb-light-blue));
            }
        }
    }
}

// Zone d'emphase
.c-emphasis {
    padding: var(--y-45) var(--x-40);
    border: 1px solid rgba(var(--rgb-marine), 0.25);
    border-top-right-radius: clamp(65px, 7vw, 100px);

    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0
    }
}
