@use "../../base/typography";

.c-overlay-calendar {
    --overlay-width: 580px;
    @media screen and (max-width: 600px) { --overlay-width: 100%; }

    --infos-height: 90px;

}

.c-overlay-calendar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 40;
    visibility: hidden;
    pointer-events: none;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 700ms var(--in-out-quart);
        z-index: -1;
        cursor: default;
    }

    &__container {
        position: absolute;
        top: 0;
        right: calc(0px - var(--overlay-width));
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        width: var(--overlay-width);
        padding-top: 90px;
        padding-bottom: var(--infos-height);
        background-color: rgb(var(--rgb-white));
        transition: right 900ms var(--in-out-quart);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 5;
            height: 110px;
            background: rgb(255,255,255);
            background: linear-gradient(180deg, rgb(255, 255, 255) 85%, rgba(0, 0, 0, 0) 100%);
        }
    }

    &__close-button {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-light-blue));
        transition: background-color 300ms;

        @media screen and (max-width: 500px) {
            width: 40px;
            height: 40px;
        }

        &:hover {
            background-color: rgb(var(--rgb-cyan));
        }

        svg {
            width: 14px;
            height: 14px;
            stroke: rgb(var(--rgb-marine));
            transition: stroke 300ms;
        }

        &:hover svg {
            stroke: rgb(var(--rgb-white));
        }
    }

    &__content {
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-right: var(--x-75);
        padding-left: var(--x-75);

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--rgb-marine), 0.25);
            border-radius: 10px;
        }
    }

    .calendar-zone {
        padding-top: 10px;
    }

    &__button {
        @extend .c-button;
        @extend .c-button--cyan-to-marine;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-stroke;
        margin-top: 30px;
        margin-bottom: 30px;

        svg {
            width: 16px;
            height: 16px;
            stroke-width: 2px;
            rotate: none;
        }
    }

    &__infos {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: var(--infos-height);
        padding-right: 30px;
        padding-left: 30px;
        border-top: 1px solid rgba(var(--rgb-marine), 0.25);

        @media screen and (max-width: 400px) {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &__infos-title {
        font-size: 1.7rem;
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));

        @media screen and (max-width: 600px) { display: none; }
    }

    &__list,
    &__list-item {
        display: flex;
        align-items: center;
    }

    &__list {
        flex-wrap: wrap;
        margin-top: -10px;
        margin-right: -30px;
    }

    &__list-item {
        margin-top: 10px;
        margin-right: 30px;

        @media screen and (max-width: 500px) { margin-right: 20px; }
    }

    &__event-title,
    &__today-title,
    &__selected-title {
        font-size: 1.4rem;
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));

        @media screen and (max-width: 500px) { font-size: 1.2rem; }
        @media screen and (max-width: 400px) { font-size: 1.1rem; }
    }

    &__event {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-right: 8px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-marine));
    }

    &__today,
    &__selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        margin-right: 8px;
        border: 1px solid transparent;
        border-radius: 3px;
        font-size: 1rem;
        font-weight: 600;
    }

    &__today {
        border-color: rgba(var(--rgb-marine), 0.7);
        color: rgb(var(--rgb-marine));
    }

    &__selected {
        border-color: rgb(var(--rgb-cyan));
        background-color: rgb(var(--rgb-cyan));
        color: rgb(var(--rgb-white));
    }
}

.show-overlay-calendar {
    .c-overlay-calendar {
        pointer-events: all;
        visibility: visible;

        &__container {
            right: 0;
        }
    }
}

.show-overlay-calendar:not(.closing-overlay-calendar) {
    .c-overlay-calendar {
        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }
}

.show-overlay-calendar.closing-overlay-calendar {
    .c-overlay-calendar {
        &__container {
            right: calc(0px - var(--overlay-width));
        }
    }
}