.c-events-component {
    --events-list-gap-x: 15px;
    --events-list-gap-y: 30px;
    @media screen and (max-width: 400px) { --events-list-gap-y: 15px; }
}

.c-events-component {
    &__title {
        margin-bottom: var(--y-60);
        font-size: var(--fz-50);
        font-weight: 700;
        line-height: 1.2em;
        color: rgb(var(--rgb-marine));

        @media screen and (max-width: 700px) {
            margin-bottom: var(--y-40);
            font-size: var(--fz-40);
        }
        @media screen and (max-width: 500px) {
            margin-bottom: 25px;
            font-size: max(var(--fz-30), 2.2rem);
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--events-list-gap-x) * 2));
        margin-right: calc(0px - var(--events-list-gap-x));
        margin-bottom: calc(var(--y-60) - var(--events-list-gap-y));
        margin-left: calc(0px - var(--events-list-gap-x));
    }

    &__list-item {
        width: 33.33%;
        padding: 0 var(--events-list-gap-x);
        margin-bottom: var(--events-list-gap-y);

        @media screen and (max-width: 1200px) { width: 50%; }
        @media screen and (max-width: 700px) { width: 100%; }
    }

    &__pagination {
        position: relative;
        overflow: hidden;
        isolation: isolate;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 95px;
        background-color: rgb(var(--rgb-marine));

        @media screen and (max-width: 600px) { height: 65px; }
    }

    &__pagination-shapes {
        position: absolute;
        top: 0;
        left: 0;
        width: 370px;
        height: 100%;

        @media screen and (max-width: 700px) { width: 100%; }
    }

    &__pagination-shape {
        position: absolute;
        width: 185px;

        &:first-child {
            top: 0;
            left: 0;
        }
        &:last-child {
            right: 0;
            bottom: 0;
        }
    }

    &__pagination-button {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding-right: 40px;
        transition: padding-right 400ms;

        @media screen and (max-width: 500px) {
            width: 100%;
            padding-left: 40px;
            justify-content: space-between;
        }

        &:hover {
            padding-right: 30px;
        }

        span {
            display: inline-block;
            margin-right: 25px;
            font-size: 1.7rem;
            font-weight: 700;
            line-height: 1.4em;
            color: rgb(var(--rgb-white));
        }

        svg {
            rotate: 90deg;
            flex-shrink: 0;
            width: 16px;
            height: 14px;
            stroke: rgb(var(--rgb-yellow));
            stroke-width: 2px;
        }
    }
}