@use "../base/typography";

.c-page-title {
    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(var(--rgb-light-blue));
    }

    &__text-block {
        width: 100%;
        padding: max(var(--y-40), 30px) var(--x-240);
    }

    &__title {
        @extend .c-huge-title;
        text-align: center;

        @media screen and (max-width: 900px) { font-size: var(--fz-60); }
        @media screen and (max-width: 600px) { font-size: max(var(--fz-50), 3rem); }
    }

    .c-button-share {
        position: absolute;
        top: 25px;
        left: 25px;

        @media screen and (max-width: 700px) { display: none; }
    }

    &__button-scrolldown {
        display: flex;
        align-items: center;
        margin-top: -5px;
        margin-bottom: 20px;

        @media screen and (max-width: 600px) { display: none; }

        span {
            display: inline-block;
            margin-right: 8px;
            font-family: var(--ff-1);
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1.4em;
            color: rgb(var(--rgb-marine));
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-cyan));
        }

        svg {
            rotate: 180deg;
            width: 14px;
            height: 13px;
            stroke: rgb(var(--rgb-cyan));
            stroke-width: 2px;
        }
    }

    &__picture-container {
        position: relative;
        isolation: isolate;
        display: flex;
        width: 100%;
        height: 380px;
        padding: 0 var(--x-240);

        @media screen and (max-width: 900px) { height: initial; }

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            height: var(--y-100);
            background-color: rgb(var(--rgb-white));

            @media screen and (max-width: 900px) { height: max(var(--y-65), 30px); }
        }
    }

    &__picture {
        position: relative;
        flex: 1;
        height: 100%;
        padding-right: 20px;

        @media screen and (max-width: 900px) {
            height: initial;
            min-height: 160px;
            padding-top: 39.7%;
        }
    }

    &__img {
        @media screen and (max-width: 900px) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__shapes {
        width: 280px;
        height: 100%;

        @media screen and (max-width: 900px) { display: none; }
    }

    &__photo-credits {
        position: absolute;
        bottom: 15px;
        left: calc(var(--x-240) + 15px);
        z-index: 5;

        @media screen and (max-width: 400px) {
            bottom: 10px;
            left: calc(var(--x-240) + 10px);
        }
    }
}

// Dans la page d'actualités et d'événements
.o-layout.--page-publications,
.o-layout.--page-events,
.o-layout.--page-search,
.o-layout.--page-contact {
    .c-page-title {
        .c-button-share {
            &:hover {
                background-color: rgb(var(--rgb-white));
            }

            &:hover svg {
                fill: rgb(var(--rgb-marine));
            }
        }
        &__content {
            background-color: rgb(var(--rgb-marine));
            isolation: isolate;
        }

        &__title {
            color: rgb(var(--rgb-white));
        }

        .c-button-share {
            display: flex;

            @media screen and (max-width: 400px) { display: none; }
        }

        &__shapes {
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            display: block;
            width: initial;
        }
    }
}

.o-layout.--page-error,
.o-layout.--page-maintenance {
    .c-button-share {
        display: none;
    }
}